import React from "react";
import { Modal,  } from "react-bootstrap";
import ReactPlayer from "react-player";

function VideoPlayer({ show, close, VideoLink }) {
  return (
    <div>
      <Modal
        show={show}
        size="lg"
        onHide={close}
        dialogClassName="rounded-lg p-0 modal-h videoPlayer "
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton className="border-bottom-0  text-white btn-close-white"></Modal.Header>
        <Modal.Body style={{minHeight :"100%    "}} className="">
          <ReactPlayer
            url={"https://storage.trotterit.com/videos/" + VideoLink}
            pip={true}
            className=""
            width={"100%"}
            controls={true}
            playing={true}
            loop
            muted={false}
            playsinline={true}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default VideoPlayer;
