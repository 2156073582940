import React from "react";
import it_content from "../../src/Assets/png/it_content.png";
import it_logo from "../../src/Assets/png/it_logo.png";
import it_content_black from "../../src/Assets/png/it_content_black.png";
import it_logo_black from "../../src/Assets/png/it_logo_black.png";

const TrotterItIcon = () => {
  const handleClick = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes("android")) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.anansi.anansi&hl=en_IN&gl=US",
        "_blank"
      );
    } else if (userAgent.includes("iphone") || userAgent.includes("ipad")) {
      window.open(
        "https://apps.apple.com/us/app/trotter-it-travel-journal-app/id6443733048",
        "_blank"
      );
    } else if (userAgent.includes("macintosh")) {
      window.open(
        "https://apps.apple.com/us/app/trotter-it-travel-journal-app/id6443733048",
        "_blank"
      );
    } else {
      window.open(
        "https://play.google.com/store/apps/details?id=com.anansi.anansi&hl=en_IN&gl=US",
        "_blank"
      );
    }
  };

  return (
    <div className="rotation-container">
      <div className="position-relative">
        <img src={it_content} alt="it_content" className="rotating-image cursor-pointer" onClick={handleClick} />
        <div className="position-absolute it_logo_positon" onClick={handleClick} >
          <img src={it_logo} alt="it_logo" className="cursor-pointer" />
        </div>
      </div>
      {/* <div className="position-relative">
        <img
          src={it_content_black}
          alt="it_content"
          className="rotating-image"
        />
        <div className="position-absolute it_logo_positon">
          <img src={it_logo_black} alt="it_logo" />
        </div>
      </div> */}
    </div>
  );
};

export default TrotterItIcon;
