import React from "react";
import Navbar from "../Components/Navbar";
import TrendingHero from "../Components/trendingPage/TrendingHero";
import TrendingCard from "../Components/trendingPage/TrendingCard";
import Footer from "../Components/Footer";

const Trending = () => {
  return (
    <div className="">
      <TrendingHero />
      <TrendingCard />
    </div>
  );
};

export default Trending;
