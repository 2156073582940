import React, { useState } from "react";
import Logo from "../Assets/svg/logo.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

function Navbar() {
  const [colorChange, setColorChange] = useState(false);
  const [navToggal, setNavToggal] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState(null);
  const { pathname } = useLocation();
  const changeNavbarColor = () => {
    if (window.scrollY >= 50) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };

  const handleClick = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes("android")) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.anansi.anansi&hl=en_IN&gl=US",
        "_blank"
      );
    } else if (userAgent.includes("iphone") || userAgent.includes("ipad")) {
      window.open(
        "https://apps.apple.com/us/app/trotter-it-travel-journal-app/id6443733048",
        "_blank"
      );
    } else if (userAgent.includes("macintosh")) {
      window.open(
        "https://apps.apple.com/us/app/trotter-it-travel-journal-app/id6443733048",
        "_blank"
      );
    } else {
      window.open(
        "https://play.google.com/store/apps/details?id=com.anansi.anansi&hl=en_IN&gl=US",
        "_blank"
      );
    }
  };

  const toggleNavMenu = () => {
    setNavToggal(true);
    // Toggle a CSS class on the body to prevent scrolling
    document.body.classList.toggle("no-scroll");
  };
  const toggleNavMenu1 = () => {
    setNavToggal(false);
    // Toggle a CSS class on the body to prevent scrolling
    document.body.classList.toggle("no-scroll");
  };

  // Add an event listener to remove the 'no-scroll' class when the menu is closed
  window.addEventListener("scroll", changeNavbarColor);
  if (navToggal) {
    window.addEventListener("scroll", () => {
      document.body.classList.remove("no-scroll");
    });
  }

  useEffect(() => {
    setNavToggal(false);
  }, [pathname]);

  return (
    <>
      <div
        className={` py-4 ${navToggal ? "nav-menu-open" : ""} ${
          colorChange ? "Navbar-1" : "mobile_nav"
        } ${
          pathname == "/" || pathname == "/travel-blog"
            ? "Navbar"
            : "bg-black position-s"
        }`}
      >
        <div className=" custom_container ">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="d-flex gap-3 align-items-center">
              <div
                className="navmanu d-lg-none d-block"
                onClick={toggleNavMenu}
              >
                <>
                  <div className="navdote"></div>
                  <div className="navdote"></div>
                  <div className="navdote"></div>
                </>
              </div>
              <Link
                className={`fs_16 cursor-pointer ff_opne color_gray2 ps-2 text-white ${
                  selectedNavItem === "" ? "fw-bold" : ""
                }`}
                to={"/"}
                onClick={() => setSelectedNavItem("")}
              >
                <img
                  width={119}
                  height={44}
                  src={Logo}
                  // onClick={() => navigate("/")}
                />
              </Link>
            </div>
            <ul className="ps-0 d-lg-flex d-none d-flex align-items-center gap-4 mb-0">
              <li>
                <Link
                  className={`fs_16 cursor-pointer ff_opne color_gray2 ps-2 text-white ${
                    selectedNavItem === "" ? "fw-bold" : ""
                  }`}
                  to={"/"}
                  onClick={() => setSelectedNavItem("")}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className={`fs_16 cursor-pointer ff_opne color_gray2 ps-2 text-white ${
                    selectedNavItem === "trending" ? "fw-bold" : ""
                  }`}
                  to={"/trending"}
                  onClick={() => setSelectedNavItem("trending")}
                >
                  Trending
                </Link>
              </li>
              {/* <li>
                <a
                  className="fs_16 cursor-pointer ff_opne color_gray2 ps-2 text-white"
                  href="#"
                >
                  Short Videos
                </a>
              </li> */}
              <li>
                <Link
                  className={`fs_16 cursor-pointer ff_opne color_gray2 ps-2 text-white ${
                    selectedNavItem === "footprint" ? "fw-bold" : ""
                  }`}
                  to={"/footprint"}
                  onClick={() => setSelectedNavItem("footprint")}
                >
                  Explore
                </Link>
              </li>
              <li>
                <Link
                  className={`fs_16 cursor-pointer ff_opne color_gray2 ps-2 text-white ${
                    selectedNavItem === "allvideos" ? "fw-bold" : ""
                  }`}
                  to={"/allvideos"}
                  onClick={() => setSelectedNavItem("allvideos")}
                >
                  AI Video
                </Link>
              </li>
              <li>
                <a
                  className={`fs_16 cursor-pointer ff_opne color_gray2 ps-2 text-white ${
                    selectedNavItem === "travel-blog" ? "fw-bold" : ""
                  }`}
                  href="https://blog.trotterit.com"
                  onClick={() => setSelectedNavItem("travel-blog")}
                >
                  Blog
                </a>
              </li>
            </ul>
            <div className="">
              <button
                className="btn signup fs_16 fw-semibold ff_opne"
                onClick={handleClick}
              >
                Get Started
              </button>
            </div>
          </div>
          <div className="position-relative ">
            {navToggal && (
              <div
                className={`navListWrapper d-flex flex-column justify-content-center align-items-center ${
                  colorChange ? "colorChange" : ""
                }`}
              >
                <img
                  className=" cross_icon"
                  width={25}
                  onClick={() => {
                    toggleNavMenu1();
                  }}
                  src="https://flaticons.net/icon.php?slug_category=mobile-application&slug_icon=close"
                  alt="cross_icon"
                />
                <ul className="navList text-center">
                  <li className="navItem">
                    <Link
                      className={`fs_16 cursor-pointer ff_opne color_gray2 ps-2 text-white ${
                        selectedNavItem === "" ? "fw-bold" : ""
                      }`}
                      to={"/"}
                      onClick={() => setSelectedNavItem("")}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="navItem">
                    <Link
                      className={`fs_16 cursor-pointer ff_opne color_gray2 ps-2 text-white ${
                        selectedNavItem === "trending" ? "fw- bold" : ""
                      }`}
                      to={"/trending"}
                      onClick={() => setSelectedNavItem("trending")}
                    >
                      Trending
                    </Link>
                  </li>
                  {/* <li className="navItem">
                    <a className="navLink" href="#">
                      Short Videos
                    </a>
                  </li> */}
                  <li className="navItem">
                    <Link
                      className={`fs_16 cursor-pointer ff_opne color_gray2 ps-2 text-white ${
                        selectedNavItem === "footprint" ? "fw-bold" : ""
                      }`}
                      to={"/footprint"}
                      onClick={() => setSelectedNavItem("footprint")}
                    >
                      <a className="navLink" href="#">
                        Explore
                      </a>
                    </Link>
                  </li>

                  <li className="navItem">
                    <Link
                      className={`fs_16 cursor-pointer ff_opne color_gray2 ps-2 text-white ${
                        selectedNavItem === "allvideos" ? "fw-bold" : ""
                      }`}
                      to={"/allvideos"}
                      onClick={() => setSelectedNavItem("allvideos")}
                    >
                      <a className="navLink" href="#">
                        AI Video
                      </a>
                    </Link>
                  </li>
                  <li className="navItem">
                    <a
                      className={`fs_16 cursor-pointer ff_opne color_gray2 ps-2 text-white ${
                        selectedNavItem === "travel-blog" ? "fw-bold" : ""
                      }`}
                      href="https://blog.trotterit.com/"
                      onClick={() => setSelectedNavItem("travel-blog")}
                    >
                      <a className="navLink" href="#">
                        Blog
                      </a>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
