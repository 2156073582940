import React from "react";
import tavell_image from "../../Assets/png/travel.png";
import you_icon from "../../Assets/png/you_icon.png";
import insta_icon from "../../Assets/png/insta_icon.png";
import x_icon from "../../Assets/png/x_icon.png";
import { getTavell } from "../../Services/Tavell";
import { useState } from "react";
import { useEffect } from "react";
import { getImageURL } from "../../Services/Common/helper";
import { useNavigate } from "react-router-dom";

const TavellHero = () => {
  const [tavell, setTavell] = useState([]);
  const navigate = useNavigate();
  const getTavellpage = async () => {
    const res = await getTavell();
    setTavell(res);
  };
  useEffect(() => {
    getTavellpage();
  }, []);
  return (
    <div className="bg-white">
      <div className="custom_container py-5">
        <h3 className=" color_gray1 fs_48 ff_opne mb-0 text-center">
          Inspiring Travellers
        </h3>
        <p className=" color_gray1 fs_16 ff_opne mb-0 text-center mt-2">
          Discover the Hottest Travel Destinations and Stories
        </p>
        <div className="row mt-5">
          {tavell?.map((obj, i) => {
            return (
              <>
                <div key={i} className="custom_col col-md-4 col-sm-6 mt-4">
                  <div className="tavell_box cursor-pointer">
                    <img
                      height={160}
                      className="w-100 object-fit-cover rounded-3"
                      src={getImageURL(obj.image)}
                      alt="tavell_image"
                      onClick={() => {
                        navigate(`/tavell-detail/${obj.id}/${obj.first_name}`);
                      }}
                    />
                    <h6 className=" fs_24 ff_opne color_gray1 mt-2 fw-bold mb-0">
                      {obj.first_name}
                    </h6>
                    <p className=" fs_16 ff_opne color_gray1 mb-0 mt-1">
                      {obj.country}
                    </p>
                    <div className="d-flex align-items-center gap-2 mt-2">
                      <img className=" cursor-pointer" src={you_icon} alt="" />
                      <img
                        className=" cursor-pointer"
                        src={insta_icon}
                        alt=""
                      />
                      <img className=" cursor-pointer" src={x_icon} alt="" />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TavellHero;
