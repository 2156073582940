import React, { useEffect } from "react";
import asia from "../../Assets/img/Rectangle 750.jpg";
import europe from "../../Assets/img/Rectangle 751.jpg";
import africa from "../../Assets/img/Rectangle 752.jpg";
import america from "../../Assets/img/Rectangle 748.jpg";
import AUSTRIALIA from "../../Assets/img/Rectangle 749.jpg";
import { BsArrowRight } from "react-icons/bs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

function Country() {
  const navigate = useNavigate();
  const [asiaImageWidth, setAsiaImageWidth] = useState("100%");
  const [europeImageWidth, setEuropeImageWidth] = useState("100%");
  const [africaImageWidth, setAfricaImageWidth] = useState("100%");
  const [americaImageWidth, setAmericaImageWidth] = useState("100%");
  const [australiaImageWidth, setAustraliaImageWidth] = useState("100%");

  const handleImageResize = (continent) => {
    // Update the image width based on the continent
    if (continent === "asia") {
      setAsiaImageWidth("100%");
    } else {
      setAsiaImageWidth("250px");
    }
    if (continent === "europe") {
      setEuropeImageWidth("100%");
    } else {
      setEuropeImageWidth("250px");
    }
    if (continent === "africa") {
      setAfricaImageWidth("100%");
    } else {
      setAfricaImageWidth("250px");
    }
    if (continent === "america") {
      setAmericaImageWidth("100%");
    } else {
      setAmericaImageWidth("250px");
    }
    if (continent === "australia") {
      setAustraliaImageWidth("100%");
    } else {
      setAustraliaImageWidth("250px");
    }
  };
  const handleImageResize1 = (continent) => {
    // Update the image width based on the continent
    if (continent === "asia") {
      setAsiaImageWidth("100%");
    } else {
      setAsiaImageWidth("250px");
    }
    if (continent === "europe") {
      setEuropeImageWidth("100%");
    } else {
      setEuropeImageWidth("250px");
    }
    if (continent === "africa") {
      setAfricaImageWidth("100%");
    } else {
      setAfricaImageWidth("250px");
    }
    if (continent === "america") {
      setAmericaImageWidth("100%");
    } else {
      setAmericaImageWidth("250px");
    }
    if (continent === "australia") {
      setAustraliaImageWidth("100%");
    } else {
      setAustraliaImageWidth("250px");
    }
  };

  return (
    <div className="countryContainer pb-5 bg-white ">
      <div className="imgConatiner position-relative d-md-block d-none cursor-pointer" onClick={() => {
        handleImageResize("asia");
        navigate("/country/asia");
      }}>
        <img
          src={asia}
          alt=""
          srcset=""
          height={"100%"}
          width={asiaImageWidth}
          className="iimg "
        />
        <div className="d-flex flex-column position-absolute end-0 justify-content-end align-items-md-end w-100 pe-3 bottom-0  py-4">
          <div className="d-flex flex-md-column align-items-center justify-content-between justify-content-md-end w-md-100">
            <h1 className="Country-text ff_opne ">ASIA</h1>
            <button
              className="btn text-white border-2 border blurbtn btn-w p-3 rounded-pill"
              onClick={() => {
                handleImageResize("asia");
                navigate("/country/asia");
              }}
            >
              <BsArrowRight />
            </button>
          </div>
        </div>
      </div>
      <div className="imgConatiner d-md-block d-none position-relative cursor-pointer" onClick={() => {
        handleImageResize("europe");
        navigate("/country/europe");
      }}>
        <img
          src={europe}
          alt=""
          srcset=""
          height={"100%"}
          width={europeImageWidth}
          className="iimg "
        />
        <div className="d-flex flex-column position-absolute justify-content-end align-items-md-end w-100 pe-3 bottom-0  py-4">
          <div className="d-flex flex-md-column align-items-center justify-content-between justify-content-md-end w-md-100">
            <h1 className="Country-text ff_opne ">EUROPE</h1>
            <button
              className="btn text-white border-2 border blurbtn btn-w p-3 rounded-pill"
              onClick={() => {
                handleImageResize("europe");
                navigate("/country/europe");
              }}
            >
              <BsArrowRight />
            </button>
          </div>
        </div>
      </div>
      <div className="imgConatiner position-relative d-md-block d-none cursor-pointer" onClick={() => {
        handleImageResize("africa");
        navigate("/country/africa");
      }}>
        <img
          src={africa}
          alt=""
          srcset=""
          height={"100%"}
          width={africaImageWidth}
          className="iimg "
        />
        <div className="d-flex flex-column position-absolute justify-content-end align-items-md-end w-100 pe-3 bottom-0  py-4">
          <div className="d-flex flex-md-column align-items-center justify-content-between justify-content-md-end w-md-100">
            <h1 className="Country-text ff_opne">AFRICA</h1>
            <button
              className="btn text-white border-2 border blurbtn btn-w p-3 rounded-pill"
              onClick={() => {
                handleImageResize("africa");
                navigate("/country/africa");
              }}
            >
              <BsArrowRight />
            </button>
          </div>
        </div>
      </div>
      <div className="imgConatiner position-relative d-md-block d-none cursor-pointer" onClick={() => {
        handleImageResize("america");
        navigate("/country/america");
      }}>
        <img
          src={america}
          alt=""
          srcset=""
          height={"100%"}
          width={americaImageWidth}
          className="iimg "
        />
        <div className="d-flex flex-column position-absolute justify-content-end align-items-md-end w-100 pe-3 bottom-0  py-4">
          <div className="d-flex flex-md-column align-items-center justify-content-between justify-content-md-end w-md-100">
            <h1 className="Country-text ff_opne">AMIERICA</h1>
            <button
              className="btn text-white border-2 border blurbtn btn-w p-3 rounded-pill"
              onClick={() => {
                handleImageResize("america");
                navigate("/country/america");
              }}
            >
              <BsArrowRight />
            </button>
          </div>
        </div>
      </div>
      {/* ----------------------------------------------------------------------------------------------------- */}
      <div className="imgConatiner position-relative d-md-none d-block cursor-pointer" onClick={() => {
        handleImageResize("asia");
        navigate("/country/asia");
      }}>
        <img
          src={asia}
          alt=""
          srcset=""
          height={asiaImageWidth}
          width={"100%"}
          className="iimg "
        />
        <div className="d-flex flex-column position-absolute end-0 justify-content-end align-items-md-end w-100 pe-3 bottom-0  py-4">
          <div className="d-flex flex-md-column align-items-center justify-content-between justify-content-md-end w-md-100">
            <h1 className="Country-text ff_opne ">ASIA</h1>
            <button
              className="btn text-white border-2 border blurbtn btn-w p-3 rounded-pill"
              onClick={() => {
                handleImageResize("asia");
                navigate("/country/asia");
              }}
            >
              <BsArrowRight />
            </button>
          </div>
        </div>
      </div>
      <div className="imgConatiner  position-relative d-md-none d-block cursor-pointer" onClick={() => {
        handleImageResize("europe");
        navigate("/country/europe");
      }}>
        <img
          src={europe}
          alt=""
          srcset=""
          height={europeImageWidth}
          width={"100%"}
          className="iimg "
        />
        <div className="d-flex flex-column position-absolute justify-content-end align-items-md-end w-100 pe-3 bottom-0  py-4">
          <div className="d-flex flex-md-column align-items-center justify-content-between justify-content-md-end w-md-100">
            <h1 className="Country-text ff_opne ">EUROPE</h1>
            <button
              className="btn text-white border-2 border blurbtn btn-w p-3 rounded-pill"
              onClick={() => {
                handleImageResize("europe");
                navigate("/country/europe");
              }}
            >
              <BsArrowRight />
            </button>
          </div>
        </div>
      </div>
      <div className="imgConatiner position-relative d-md-none d-block cursor-pointer" onClick={() => {
        handleImageResize("africa");
        navigate("/country/africa");
      }}>
        <img
          src={africa}
          alt=""
          srcset=""
          height={africaImageWidth}
          width={"100%"}
          className="iimg "
        />
        <div className="d-flex flex-column position-absolute justify-content-end align-items-md-end w-100 pe-3 bottom-0  py-4">
          <div className="d-flex flex-md-column align-items-center justify-content-between justify-content-md-end w-md-100">
            <h1 className="Country-text ff_opne">AFRICA</h1>
            <button
              className="btn text-white border-2 border blurbtn btn-w p-3 rounded-pill"
              onClick={() => {
                handleImageResize("africa");
                navigate("/country/africa");
              }}
            >
              <BsArrowRight />
            </button>
          </div>
        </div>
      </div>
      <div className="imgConatiner position-relative d-md-none d-block cursor-pointer" onClick={() => {
        handleImageResize("america");
        navigate("/country/america");
      }}>
        <img
          src={america}
          alt=""
          srcset=""
          height={americaImageWidth}
          width={"100%"}
          className="iimg "
        />
        <div className="d-flex flex-column position-absolute justify-content-end align-items-md-end w-100 pe-3 bottom-0  py-4">
          <div className="d-flex flex-md-column align-items-center justify-content-between justify-content-md-end w-md-100">
            <h1 className="Country-text ff_opne">AMIERICA</h1>
            <button
              className="btn text-white border-2 border blurbtn btn-w p-3 rounded-pill"
              onClick={() => {
                handleImageResize("america");
                navigate("/country/america");
              }}
            >
              <BsArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Country;
