import React from "react";
import { useSelector } from "react-redux";
import { getImageURL, removeUnwanted, replaceSpaceWithHyphen } from "../Services/Common/helper";
import { useNavigate } from "react-router-dom";
import eye from "../Assets/png/Vector 8.png";
import track from "../Assets/png/Vector 9.png";

function FootPrints() {
  const haltsData = useSelector((state) => state.SearchDetails.data);
  const navigate = useNavigate();
  return (
    <div className="bg-white pb-5">
      <div className="custom_container">
        <h2 className="display-6 ff_opne pt-4">Trips</h2>
        <div className="row justify-content-center">
          {haltsData?.map((items, index) => {
            return (
              <>
                <div
                  className="card-box col-md-4 col-sm-6 position-relative mt-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(
                      `/stories/${items?.halt_info.trip_id}/${
                        replaceSpaceWithHyphen(items?.user_info.first_name) +
                        "-" +
                        replaceSpaceWithHyphen(items?.user_info.last_name)
                      }/${
                        items.halt_info.halt_title
                          ? replaceSpaceWithHyphen(items.halt_info.halt_title)
                          : "travel"
                      }`
                    );
                  }}
                >
                  <div className="tripimg">
                    <img
                      src={
                        "https://storage.trotterit.com/images/halt/" +
                        items?.halt_info?.media[0]?.media
                      }
                      className="cardimg"
                    />
                  </div>
                  <div className="position-absolute top-0 tripdetails p-3 w-100 pe-3">
                    <div className="d-flex flex-column justify-content-between h-100 pe-4">
                      <div className="d-flex gap-2">
                        <div className="userPic">
                          <img
                            src={getImageURL(items?.user_info.image)}
                            className="cardimg"
                          />
                        </div>
                        <div>
                          <span className="text-white">
                            {items?.user_info.first_name +
                              " " +
                              items?.user_info.last_name}
                          </span>
                        </div>
                      </div>
                      <div>
                        <h3 className="text-white">
                          {removeUnwanted(items?.halt_info.halt_title)}
                        </h3>
                      </div>
                      <div className="d-flex justify-content-between text-white">
                        <div className="d-flex flex-column align-items-center">
                          <span className="text-white">
                            {items?.halt_info.view_count}
                          </span>
                          <div>
                            <img src={eye} alt="" srcset="" />
                          </div>
                        </div>
                        {/* <div className="d-flex flex-column align-items-center">
                          <div>
                            <img src={track} />
                          </div>
                          <div>
                            <p>
                              {items.halt_count} Footprints{" "}
                              {Math.round(items?.halt_info.total_distance)} Km
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FootPrints;
