import React from "react";
import Slider from "react-slick";
import slider_image1 from "../../src/Assets/png/slider_image1.png";
import RohitPatial from "../Assets/Review/Rohit Patial.jpg"
import Sidh from "../Assets/Review/Siddharth Singha.jpg"
import Varsha from "../Assets/Review/Varsha Chawla.jpg"
import Hameer from "../Assets/Review/Hameer jhala.jpg"

const Tourists = () => {
  return (
    <div className="bg-white py-5">
      <div className="pb-md-5 mb-md-5 custom_container">
        <h4 className=" fs_48 fw-semibold text-center color_gray1 ff_opne mb-0">
          What Travelers Say About Us
        </h4>
        <div className="pt-md-5 pt-3 row justify-content-center">
          {/* <Slider className="m-0 w-100" {...settings}> */}
          <div className="px-2 py-3 col-lg-3 col-md-4 col-sm-6  pt-5 mt-4">
            <div className="tourists_box h-100 position-relative  d-flex flex-column justify-content-between">
              <img
                className=" position-absolute slider_image1 card_image"
                height={"100px"}
                width={100}
                src={RohitPatial}
                alt="slider_image1"
              />
              <p className="fs_18 ff_opne color_gray2">
                "Love the app's user-friendly interface, making it easy to
                navigate and share my travel experiences with friends and the
                community."
              </p>
              <div className="text-end mt-4">
                <i className="fs_16 ff_opne color-gray2">– Rohit Patial </i>
                <br></br>
                <i className="fs_16 ff_opne color-gray2">
                  Adventure Enthusiast
                </i>
                <br></br>

                <i className="fs_16 ff_opne color-gray2">India </i>
              </div>
            </div>
          </div>
          <div className="px-2 py-3 col-lg-3 col-md-4 col-sm-6  pt-5 mt-4">
            <div className="tourists_box h-100 position-relative  d-flex flex-column justify-content-between">
              <img
                className=" position-absolute slider_image1 card_image"
                height={"100px"}
                width={100}
                src={Sidh}
                alt="slider_image1"
              />
              <p className="fs_18 ff_opne color_gray2">
                "I appreciate the privacy controls that allow me to choose who
                can see my posts, ensuring my safety and security while sharing
                my adventures."
              </p>
              <div className="text-end mt-4">
                <i className="fs_16 ff_opne color-gray2">– Sidharth Singha</i>
                <br></br>
                <i className="fs_16 ff_opne color-gray2">Travel Explorer</i>
                <br></br>

                <i className="fs_16 ff_opne color-gray2">America </i>
              </div>
            </div>
          </div>
          <div className="px-2 py-3 col-lg-3 col-md-4 col-sm-6  pt-5 mt-4">
            <div className="tourists_box h-100 position-relative  d-flex flex-column justify-content-between">
              {" "}
              <img
                className=" position-absolute slider_image1 card_image"
                height={"100px"}
                width={100}
                src={Varsha}
                alt="slider_image1"
              />
              <p className="fs_18 ff_opne color_gray2">
                "The real-time location tracking feature is fantastic for
                finding local recommendations during my travels."
              </p>{" "}
              <div className="text-end mt-4">
                <i className="fs_16 ff_opne color-gray2">– Varsha Chawla </i>
                <br></br>
                <i className="fs_16 ff_opne color-gray2">Solo Adventurer</i>
                <br></br>

                <i className="fs_16 ff_opne color-gray2">India </i>
              </div>
            </div>
          </div>
          <div className="px-2 py-3 col-lg-3 col-md-4 col-sm-6  pt-5 mt-4">
            <div className="tourists_box h-100 position-relative  d-flex flex-column justify-content-between">
              {" "}
              <img
                className=" position-absolute slider_image1 card_image"
                height={"100px"}
                width={100}
                src={Hameer}
                alt="slider_image1"
              />
              <p className="fs_18 ff_opne color_gray2">
                "I can't get enough of the breathtaking travel photos and
                inspiring stories shared by fellow travelers. It keeps my
                wanderlust alive."
              </p>{" "}
              <div className="text-end mt-4">
                <i className="fs_16 ff_opne color-gray2">– Hameer Jhala </i>
                <br></br>
                <i className="fs_16 ff_opne color-gray2">Wanderlust Nomad</i>
                <br></br>

                <i className="fs_16 ff_opne color-gray2">India </i>
              </div>
            </div>
          </div>
          {/* </Slider> */}
        </div>
      </div>
    </div>
  );
};

export default Tourists;
