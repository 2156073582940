import React, { useEffect, useState } from "react";

import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import SearchSlice, {
  fetchSearchDetails,
} from "../../Redux/Search/SearchSlice";

function SearchInput() {
  const [data, setData] = useState(false);
  const [value, setValue] = useState(null);
  const dispatch = useDispatch(SearchSlice);
  const searchData = useSelector((state) => state.SearchDetails.data);
  const [selectedLocation, setSelectedlocation] = useState({
    lat: "",
    loc: "",
  });

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showPosition = (position) => {
    if (searchData.length == 0) {
      setSelectedlocation({
        lat: position.coords.latitude,
        loc: position.coords.longitude,
      });
    }
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
      default:
        alert("An error occurred while fetching location.");
        break;
    }
  };

  useEffect(() => {
    getLocation();
    setTimeout(() => {
      setData(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (selectedLocation.lat !== "") {
      dispatch(fetchSearchDetails(selectedLocation));
    }
  }, [selectedLocation]);

  const handleChange = async (newValue) => {
    try {
      const placeId = newValue.value.place_id;
      const geocodeResults = await geocodeByPlaceId(placeId);
      const latLng = await getLatLng(geocodeResults[0]);
      setSelectedlocation({ lat: latLng.lat, loc: latLng.lng });
    } catch (error) {
      console.error("Error getting geolocation:", error);
    }
    setValue(newValue);
  };

  return (
    <div className="d-flex justify-content-center w-100">
      <div className="px-4 searchInput2 mx-auto ">
        {data ? (
          <GooglePlacesAutocomplete
            apiKey="AIzaSyC8FHSpqqDELhnumtiCqWs0BWYbkJRfXM8"
            placeholder="Search for a location..."
            selectProps={{
              value,
              onChange: handleChange,
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default SearchInput;
