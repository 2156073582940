import React from "react";
import addimage from "../Assets/png/1.png";
import addimage1 from "../Assets/png/2.png";
import addimage3 from "../Assets/png/3.png";
import addimage4 from "../Assets/png/4.png";
import click_image from "../Assets/png/click_image.png";
const AddFeatured = () => {
  return (
    <div className="bg_addfeatured py-5">
      <div className="custom_container">
        <h3 className="text-center fs_48 ff_opne text-white fw-semibold">
          We’re Featured In
        </h3>
        <div className="mt-5 row">
          <div className="col-md-3 col-6 position-relative cursor-pointer transform_rotate">
            <a
              className=""
              target="_blank"
              href="https://m.dailyhunt.in/news/india/english/hindustan+metro-epaper-dh6ae7922f205548bc994645e1b7a6d8d9/trotter+it+elevate+your+travel+journal+experience+on+android-newsid-dh6ae7922f205548bc994645e1b7a6d8d9_02a3923045b711eebe7707a4512c2e25 "
            >
              <img
                className="w-100 rounded-4 news_image"
                src={addimage}
                alt="addimage"
              />
            </a>
            <div className="position-absolute top-50 translate-middle start-50">
              <a
                className=""
                target="_blank"
                href="https://m.dailyhunt.in/news/india/english/hindustan+metro-epaper-dh6ae7922f205548bc994645e1b7a6d8d9/trotter+it+elevate+your+travel+journal+experience+on+android-newsid-dh6ae7922f205548bc994645e1b7a6d8d9_02a3923045b711eebe7707a4512c2e25 "
              >
                {/* <img src={click_image} alt="" /> */}
              </a>
            </div>
          </div>
          <div className="col-md-3 col-6 position-relative cursor-pointer">
            <a
              target="_blank"
              href="https://www.mid-day.com/brand-media/article/trotter-it-free-travel-journal-and-travel-social-media-app-23307604"
            >
              {" "}
              <img
                className="w-100 rounded-4 news_image"
                src={addimage1}
                alt="addimage"
              />
            </a>
            <div className="position-absolute top-50 translate-middle start-50">
              <a
                target="_blank"
                href="https://www.mid-day.com/brand-media/article/trotter-it-free-travel-journal-and-travel-social-media-app-23307604"
              >
                {/* <img src={click_image} alt="" /> */}
              </a>
            </div>
          </div>
          <div className="col-md-3 col-6 position-relative cursor-pointer mt-3 mt-md-0">
            <a
              target="_blank"
              href="https://thestartupstory.co.in/index.php/2023/08/31/trotter-it-your-ultimate-travel-journal-app/ "
            >
              {" "}
              <img
                className="w-100 rounded-4 news_image"
                src={addimage3}
                alt="addimage"
              />
            </a>
            <div className="position-absolute top-50 translate-middle start-50">
              <a
                target="_blank"
                href="https://thestartupstory.co.in/index.php/2023/08/31/trotter-it-your-ultimate-travel-journal-app/ "
              >
                {/* <img src={click_image} alt="" /> */}
              </a>
            </div>
          </div>
          <div className="col-md-3 col-6 position-relative cursor-pointer mt-3 mt-md-0">
            <a
              target="_blank"
              href="https://www.deccanherald.com/brandspot/sponsored/5-free-and-best-travel-diary-apps-to-preserve-your-travel-memories-2701034 "
            >
              {" "}
              <img
                className="w-100 rounded-4 news_image"
                src={addimage4}
                alt="addimage"
              />
            </a>
            <div className="position-absolute top-50 translate-middle start-50">
              <a
                target="_blank"
                href="https://www.deccanherald.com/brandspot/sponsored/5-free-and-best-travel-diary-apps-to-preserve-your-travel-memories-2701034 "
              >
                {/* <img src={click_image} className="clickimage" alt="" /> */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFeatured;
