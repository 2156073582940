import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Carousel, Modal } from "react-bootstrap";
import { removeUnwanted } from "../../../Services/Common/helper";
import ReactPlayer from "react-player";

function StorySingleHaltDetails({
  show,
  close,
  selectHalt,
  userDetails,
  ClickedImage,
}) {
  const [activeIndex, setActiveIndex] = useState(0); // State to keep track of active slide index
  // setActiveIndex(ClickedImage)
  const handleCarouselSelect = (selectedIndex) => {
    // Update the state variable when the slide changes
    setActiveIndex(selectedIndex);
  };

  useEffect(() => {
    setActiveIndex(ClickedImage);
  }, [ClickedImage]);
  return (
    <div>
      <Modal
        show={show}
        size="lg"
        onHide={close}
        dialogClassName="rounded-lg p-0 modal-h"
        style={{ height: "100%" }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton className="border-bottom-0">
          <h1 className="display-6">{removeUnwanted(userDetails?.title)}</h1>
        </Modal.Header>
        <Modal.Body className="">
          <div>
            <h4>{removeUnwanted(selectHalt?.halt_title)}</h4>
            <span>{removeUnwanted(selectHalt?.place)}</span>
            <p>{removeUnwanted(selectHalt?.why_halted)}</p>
          </div>
          <Carousel
            activeIndex={activeIndex}
            onSelect={handleCarouselSelect}
            className=" mt-4"
          >
            {selectHalt?.media?.map((item, index) => (
              <Carousel.Item key={index}>
                {item?.media.endsWith("mp4") ? (
                  <ReactPlayer
                    url={`https://storage.trotterit.com/images/halt/${item?.media}`}
                    autoplay={true}
                    className="w-100 h-100"
                    controls
                    muted
                  />
                ) : (
                  <img
                    src={`https://storage.trotterit.com/images/halt/${item?.media}`}
                    alt={`Carousel item ${index}`}
                    width={"100%"}
                    height={"500px"}
                    className="rounded-lg"
                    style={{ objectFit: "cover" }}
                  />
                )}
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default StorySingleHaltDetails;
