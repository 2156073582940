import axios from "axios";
import { getToken } from "./AuthApi";
import { axiosInstance } from "./Common/AxiosInstanc";
import { decryptResponse } from "./Common/helper";

export const getUserDetails = async (id) => {
  const res = await axiosInstance({
    url: `profile/${id}`,
    method: "GET",
  });

  if (res.status === 200) {
    const key = localStorage.getItem("aax");
    const re = decryptResponse(key, res?.data?.data);
    return re;
  }
};

export const getUserStats = async (id) => {
  const res = await axiosInstance({
    url: `stats/${id}`,
    method: "GET",
  });

  if (res.status === 200) {
    const key = localStorage.getItem("aax");
    const re = decryptResponse(key, res?.data?.data);
    return re;
  }
};

export const sendOtp = async (email) => {
  try {
    const res = await axiosInstance({
      url: `admin/email_verify`,
      method: "POST",
      data: {
        email: email,
      },
    });
    if (res.status === 200) {
      const key = localStorage.getItem("aax");
      const re = decryptResponse(key, res?.data?.data);
      return re;
    }
  } catch (error) {
    console.log(error);
  }
};

export const verifyOtp = async (user_id, otp) => {
  try {
    const res = await axiosInstance({
      url: `admin/verify_otp`,
      method: "POST",
      data: {
        user_id: user_id,
        otp: otp,
        type: "EMAIL_VERIFICATION",
      },
    });
    if (res.data.status !== "fail") {
      return res?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteUser = async (user_id) => {
  const res = await axios({
    url: `https://apis.trotterit.com/v2/delete_user_admin/${user_id}`,
    method: "POST",
    headers: {
      "x-access-token": await getToken(),
    },
  });
  if (res.status === 200) {
    return true;
  }
};
