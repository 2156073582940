import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTrendingTrpis } from "../../Services/HomeServices";

// Define an async thunk to fetch trending trips
export const fetchTrendingTrips = createAsyncThunk(
  "Trending/fetchTrendingTrips",
  async (page) => {
    try {
      const data = await getTrendingTrpis(page); // You may need to pass any required parameters to getTrendingTrips.
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const trendingSlice = createSlice({
  name: "Trending",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrendingTrips.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTrendingTrips.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchTrendingTrips.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default trendingSlice.reducer;
