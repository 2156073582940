import React, { useState } from "react";
import img1 from "../../Assets/png/editor_1.jpg";
import img4 from "../../Assets/png/editor_4.jpg";
import img5 from "../../Assets/png/editor_5.png";
import img3 from "../../Assets/png/editor_3.jpg";
import img2 from "../../Assets/png/editor_2.jpg";
import Slider from "react-slick";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

function CarouselConatiner() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [beforeChange, setBeforeChange] = useState(0);
  const navigate = useNavigate();
  const [reset, setReset] = useState(true);
  var settings = {
    dots: false,
    // infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 4000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (current, next) => setCurrentSlide(next),
    afterChange: function (index) {
      setBeforeChange(index);
    },
  };

  const images = [img1, img2, img3, img4, img5];
  const currentImage = images[currentSlide];
  const beforeImage = images[beforeChange];

  const [imageClass, setImageClass] = useState("slide-transition");

  useEffect(() => {
    setImageClass("slide-transition");
    const resetTimeout = setTimeout(() => {
      setImageClass("slide-reset");
    }, 4000);
    return () => clearTimeout(resetTimeout);
  }, [currentImage]);

  return (
    <div className=" bg-white min-vh-100 overflow-hidden">
      <div className="position-relative">
        <img
          className={`w-100 image_custom_hight`}
          src={beforeImage}
          alt="slider_image1"
        />
        <div className="position-absolute top-0 h-100 w-100">
          <img
            className={`w-100 image_custom_hight ${imageClass}`}
            src={currentImage}
            alt="slider_image1"
          />
        </div>
        <div className="bg-overlay position-absolute top-0 w-100 h-100">
          <div className="custom_container  d-flex flex-column justify-content-between position-absolute top-0 h-100 start-50 translate-middle-x w-100">
            <div className="d-md-flex align-items-center justify-content-between pt-md-5 pt-4">
              <div>
                <h4 className="fs_48 fw-semibold text-white ff_opne">
                  Editor Choice
                </h4>
                <p className="fs_20 text-white ff_opne">
                  Explore a few travel stories that are our favourite
                </p>
              </div>
              <Link to="">
                <button className=" signup  fs_16 fw-semibold ff_opne">
                  Explore
                </button>
              </Link>
            </div>
            <div className="row justify-content-between pb-4 pb-lg-5 align-items-center">
              <div className="col-lg-5 ">
                <h3 className=" fs_48 fw-bold ff_opne text-white mb-0">
                  Curated Travel Stories
                </h3>
                {/* <p classNa/editor-choiceme=" fs_24 fw-bold ff_opne text-white mb-0">
                Jatayu Nature Park
              </p> */}
                <p className=" fs_16 ff_opne text-white">
                  In this curated collection, we bring you the finest travel
                  experiences handpicked by our expert editors. These
                  captivating tales take you on extraordinary journeys,
                  immersing you in the sights, sounds, and wonders of the world.
                  Whether you're seeking inspiration for your next adventure or
                  simply want to indulge in the art of storytelling, our
                  Editor's Choice Travel Stories promise to transport you to the
                  most enchanting destinations, one captivating tale at a time.
                </p>
                <Link to="/editor-choice">
                  <button className=" bg-transparent border px-4 fs-3 text-white rounded-5">
                    &rarr;
                  </button>
                </Link>
              </div>
              <div className="col-lg-7 mt-4 mt-md-0">
                <Slider className="w-100 custom_trasform" {...settings}>
                  <div
                    className=" px-md-2 pe-2 position-relative"
                    onClick={() => {
                      navigate("/editor-choice");
                    }}
                  >
                    <img
                      height={250}
                      className="w-100 rounded-4"
                      src={img1}
                      alt="slider_image1"
                    />
                    <div className="position-absolute bottom-0 ps-2">
                      <h5 className=" fs_16 ff_opne fw-bold text-white mb-0">
                        PARADICE SWITZERLAND
                      </h5>
                      <p className=" ff_opne text-white">Swiss alps</p>
                    </div>
                  </div>
                  <div
                    className=" px-md-2 pe-2 position-relative"
                    onClick={() => {
                      navigate("/editor-choice");
                    }}
                  >
                    <img
                      height={250}
                      className="w-100 rounded-4"
                      src={img2}
                      alt="slider_image1"
                    />
                    <div className="position-absolute bottom-0 ps-2">
                      <h5 className=" fs_16 ff_opne fw-bold text-white mb-0">
                        PARADICE SWITZERLAND
                      </h5>
                      <p className=" ff_opne text-white">Swiss alps</p>
                    </div>
                  </div>
                  <div
                    className=" px-md-2 pe-2 position-relative"
                    onClick={() => {
                      navigate("/editor-choice");
                    }}
                  >
                    <img
                      height={250}
                      className="w-100 rounded-4"
                      src={img3}
                      alt="slider_image1"
                    />
                    <div className="position-absolute bottom-0 ps-2">
                      <h5 className=" fs_16 ff_opne fw-bold text-white mb-0">
                        PARADICE SWITZERLAND
                      </h5>
                      <p className=" ff_opne text-white">Swiss alps</p>
                    </div>
                  </div>
                  <div
                    className=" px-md-2 pe-2 position-relative"
                    onClick={() => {
                      navigate("/editor-choice");
                    }}
                  >
                    <img
                      height={250}
                      className="w-100 rounded-4"
                      src={img4}
                      alt="slider_image1"
                    />
                    <div className="position-absolute bottom-0 ps-2">
                      <h5 className=" fs_16 ff_opne fw-bold text-white mb-0">
                        PARADICE SWITZERLAND
                      </h5>
                      <p className=" ff_opne text-white">Swiss alps</p>
                    </div>
                  </div>
                  <div
                    className=" px-md-2 pe-2 position-relative"
                    onClick={() => {
                      navigate("/editor-choice");
                    }}
                  >
                    <img
                      height={250}
                      className="w-100 rounded-4"
                      src={img5}
                      alt="slider_image1"
                    />
                    <div className="position-absolute bottom-0 ps-2">
                      <h5 className=" fs_16 ff_opne fw-bold text-white mb-0">
                        PARADICE SWITZERLAND
                      </h5>
                      <p className=" ff_opne text-white">Swiss alps</p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarouselConatiner;
