import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import heloo from "../../Assets/png/allvideo_image1.png";
import { stateNumbers } from "../common/helper";
import upload_image from "../../Assets/png/upload_image.png";
import { useRef } from "react";
import PhoneInput from "react-phone-number-input";
import { uploadWebVfx } from "../../Services/Vfx";

const VideoDetailsComponent = ({ videoData }) => {
  const [isMagicStarted, setIsMagicStarted] = useState(false);
  const [value, setValue] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const fileInputRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const formData = new FormData();
  const token = localStorage.getItem("auth-token");

  const handleStartMagic = () => {
    if (selectedImages.length > 0) {
      setIsMagicStarted(true);
      setError("");
    } else {
      setError("Please select at least one image to start the magic.");
    }
  };
  if (!videoData) {
    return null;
  }

  // const handleImageChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   setSelectedImages([...selectedImages, ...files]);
  // };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      setSelectedImages((prevImages) => [...prevImages, file]);
    });
  };

  const handleUploadImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const contactDetails = {
    name: "",
    phoneNo: "",
    email: "",
  };
  const price = videoData.actual_price * 100;
  const uploadInfo = async () => {
    // formData.append("name", value.name);
    formData.append("phone", phoneNumber);
    formData.append("template_id", videoData.template_id);
    formData.append("email", value.email);
    formData.append("price", price);
    selectedImages.forEach((image) => {
      formData.append(`images`, image);
    });
    await uploadWebVfx(formData, token);
  };

  const submited = (e) => {
    e.preventDefault();
    if (value.name !== "" && value.phoneNo !== "" && value.email !== "") {
      // setIsFormSubmitted(true);
      uploadInfo();
    } else {
      alert("not submited");
      setValue(contactDetails);
    }
  };
  return (
    <div className="py-5 bg-white position-relative">
      <div className="custom_container py-5">
        <div className="row">
          <div className="col-md-6">
            <div className="videodetail_box">
              <h2 className=" fs_36 ff_opne mb-3">{videoData.title}</h2>
              <ReactPlayer
                url={
                  "https://storage.trotterit.com/videos/template/" +
                  videoData.media
                }
                pip={true}
                controls={false}
                playing={true}
                loop
                muted={true}
                playsinline={true}
                className="w-100 rounded-3 overflow-hidden video_card vi2"
              />
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            {isMagicStarted ? (
              // Content when magic has started
              <div className="border-2 border h-100 rounded-4 p-3">
                <div className="d-flex flex-column justify-content-between h-100">
                  <div>
                    <p className="mb-0 fs_20 fw-semibold ff_opne color_gray1">
                      Contact Details
                    </p>
                    <p className="mb-0 fs_16 ff_opne color_gray3 mt-2">
                      You've reached the final step to reveal the magic before
                      checking out. Simply provide your contact details, and
                      we'll deliver the processed video to you.
                    </p>
                    <form action="">
                      <div className="row ps-5 mt-4">
                        <div className="col-4">
                          <p className=" fs_16 fw-semibold ff_opne color_gray1">
                            Name
                          </p>
                        </div>
                        <div className="col-8 d-flex gap-3">
                          <p className="fs_16 fw-semibold ff_opne color_gray1">
                            :
                          </p>
                          <div className="w-100">
                            <input
                              target="_blank"
                              required
                              onChange={(e) =>
                                setValue({ ...value, name: e.target.value })
                              }
                              className="w-100 video_input fs_16 ff_opne color_gray3"
                              type="text"
                              value={value.name}
                            />
                            <div className="border mt-2"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row ps-5 mt-4">
                        <div className="col-4">
                          <p className=" fs_16 fw-semibold ff_opne color_gray1">
                            Mobile Number
                          </p>
                        </div>
                        <div className="col-8 d-flex gap-3">
                          <p className="fs_16 fw-semibold ff_opne color_gray1">
                            :
                          </p>
                          <div className="d-flex w-100 gap-4">
                            {/* <div className="">
                              <select className="w-100" name="" id="">
                                {stateNumbers.map((obj, i) => {
                                  return (
                                    <>
                                      <option value="">{obj.dial_code}</option>
                                    </>
                                  );
                                })}{" "}
                              </select>
                              <div className="border mt-2 w-100"></div>
                            </div> */}
                            <div className="w-100">
                              {/* <input
                                target="_blank"
                                required
                                onChange={(e) =>
                                  setValue({
                                    ...value,
                                    phoneNo: e.target.value,
                                  })
                                }
                                className="w-100 video_input fs_16 ff_opne color_gray3"
                                type="number"
                                value={value.phoneNo}
                              /> */}
                              <PhoneInput
                                className="d-flex"
                                placeholder="Enter phone number"
                                defaultCountry="IN"
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                              />
                              <div className="border mt-2"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row ps-5 mt-4">
                        <div className="col-4">
                          <p className=" fs_16 fw-semibold ff_opne color_gray1">
                            Email
                          </p>
                        </div>
                        <div className="col-8 d-flex gap-3">
                          <p className="fs_16 fw-semibold ff_opne color_gray1">
                            :
                          </p>
                          <div className="w-100">
                            <input
                              target="_blank"
                              required
                              onChange={(e) =>
                                setValue({ ...value, email: e.target.value })
                              }
                              className="w-100 video_input fs_16 ff_opne color_gray3"
                              type="email"
                              value={value.email}
                            />
                            <div className="border mt-2 w-100"></div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0 fs_22 fw-semibold color_gray1 ff_opne">
                      ₹ {videoData.actual_price}
                    </p>
                    <button
                      className="try_btn fs_16 fw-semibold ff_opne text-white"
                      onClick={(e) => submited(e)}
                    >
                      Proceed to Checkout
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              // Content when magic has not started
              <div className="border-2 border h-100 rounded-4 p-3">
                <div className="d-flex flex-column justify-content-between h-100">
                  <div>
                    <p className="mb-0 fs_16 ff_opne color_gray1">
                      Drag & drop your preferred images or videos, or simply
                      browse and select them to work the magic.
                    </p>
                    <p className="mb-0 fs_16 ff_opne color_gray3 mt-2">
                      Recommended size: 400px x 600px
                    </p>
                    <div className="mb-3 d-flex gap-3 align-items-center flex-wrap ">
                      {selectedImages.map((image, index) => (
                        <div
                          key={index}
                          className="selected-image-container mt-2"
                        >
                          <img
                            width={100}
                            height={100}
                            src={URL.createObjectURL(image)}
                            alt={`Selected Image ${index}`}
                            className="selected-image rounded-4"
                          />
                          {/* <button
                            className="remove-image-button"
                            onClick={() => removeImage(index)}
                          >
                            Remove
                          </button> */}
                        </div>
                      ))}
                    </div>

                    <input
                      type="file"
                      name="image"
                      id="image"
                      accept="image/*"
                      onChange={handleImageChange}
                      multiple
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                    <div className=" mt-2">
                      <img
                        onClick={handleUploadImageClick}
                        className=" cursor-pointer"
                        src={upload_image}
                        alt="upload_image"
                      />
                    </div>
                    {selectedImages.length < 1 ? (
                      <>
                        {error && (
                          <p className="error-message fs_16 ff_opne mt-2 ">
                            {error}
                          </p>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0 fs_22 fw-semibold color_gray1 ff_opne">
                      ₹ {videoData.actual_price}
                    </p>
                    <button
                      className="try_btn fs_16 fw-semibold ff_opne text-white"
                      onClick={handleStartMagic}
                    >
                      Start the Magic
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isFormSubmitted && (
              <div className="bg">
                <div className="congratulations-popup position-absolute top-50 start-50 translate-middle position-relative">
                  <img
                    className=" position-absolute cross_icon_position cursor-pointer"
                    width={30}
                    onClick={() => setIsFormSubmitted(false)}
                    src="https://icons.veryicon.com/png/o/miscellaneous/medium-thin-linear-icon/cross-23.png"
                    alt=""
                  />

                  <div className="mt-5">
                    <a
                      target="_blank"
                      className="fs_16 fw-semibold "
                      href="https://wa.me/919910175346"
                    >
                      If you have any queries you can message on WhatsApp.
                    </a>
                    <div className="videodetail_box mt-2">
                      <div className="d-flex align-items-center justify-content-between">
                        {" "}
                        <p className="mb-0 fs_22  fw-semibold color_gray1 ff_opne">
                          ₹ {videoData.actual_price}
                        </p>
                        <a
                          target="_blank"
                          href="http://paypal.me/RaghavRaghavendera"
                          className="try_btn fs_16 fw-semibold ff_opne
                          text-white"
                          onClick={handleStartMagic}
                        >
                          Buy Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* {isFormSubmitted && (
              <div className="congratulations-popup position-absolute top-50 start-50 translate-middle text-center ">
                <img
                  width={30}
                  onClick={() => setIsFormSubmitted(false)}
                  src="https://icons.veryicon.com/png/o/miscellaneous/medium-thin-linear-icon/cross-23.png"
                  alt=""
                />
                <div className="mt-2">
                  {" "}
                  <div className="videodetail_box">
                    <h2 className=" fs_36 ff_opne mb-3">{videoData.title}</h2>
                    <ReactPlayer
                      url={
                        "https://storage.trotterit.com/videos/" +
                        videoData.media
                      }
                      pip={true}
                      controls={false}
                      playing={true}
                      loop
                      muted={true}
                      playsinline={true}
                      className="w-100 rounded-3 overflow-hidden video_card1"
                    />
                  </div>
                </div>
                <p className=" fs_24 ff_opne fw-semibold">Congratulations..!</p>
                <p>
                  "Celebrate your journey's next chapter! Your video is on its
                  way, undergoing a transformation into a memorable masterpiece.
                  Your eagerly anticipated wanderlust tale will soon land in
                  your inbox, keeping the thrill of adventure alive."
                </p>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoDetailsComponent;
