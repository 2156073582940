import { axiosInstance } from "./Common/AxiosInstanc";
import { decryptResponse } from "./Common/helper";

export const getEditorChoice = async (index) => {
  const response = await axiosInstance({
    url: `editor_choice_trips`,
    method: "GET",
  });
  if (response.status === 200) {
    const key = localStorage.getItem("aax");
    const re = decryptResponse(key, response?.data?.data);
    return re;
  }
};
