import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getImageURL } from "../../Services/Common/helper";
import { useDispatch, useSelector } from "react-redux";
import userDetailsReducers, {
  fetchuserDetails,
} from "../../Redux/userDetails/userDetailsReducers";

const ProfileHero = () => {
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState("");
  const dispatch = useDispatch(userDetailsReducers);
  const UserData = useSelector((state) => state.User.data);
  const getUserDetail = async () => {
    setUserDetails(UserData);
  };

  useEffect(() => {
    dispatch(fetchuserDetails(id));
  }, []);

  useEffect(() => {
    getUserDetail();
  }, [UserData]);
  return (
    <div className="bg-white py-5">
      <div className="custom_container">
        <div className="image_profile d-flex align-items-end ">
          {" "}
          <div className=" w-100">
            {/* <img className="w-100" src={profile1} alt="profile1" /> */}
            <div className=" ">
              <div className="d-sm-flex justify-content-between w-100 align-items-end gap-3">
                <div className="d-flex ps-4 pb-4 align-items-end gap-3">
                  <div className="userPic2 cursor-pointer">
                    <img
                      src={getImageURL(userDetails?.image)}
                      className="cardimg"
                    />
                  </div>
                  <div>
                    <h5 className=" fs_24 fw-semibold ff_opne text-white mb-1">
                      {userDetails?.first_name + " " + userDetails?.last_name}
                    </h5>
                    <p className=" fs_16 fw-normal ff_opne text-white mb-0">
                      {userDetails?.state}, {userDetails?.country}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-5 pe-4 pb-4 ps-4 ps-sm-0">
                  <div>
                    <h5 className=" fs_20 fw-semibold ff_opne text-white mb-1">
                      {userDetails?.followers_count}
                    </h5>
                    <p className="fs_16 fw-normal ff_opne text-white mb-0">
                      Followers
                    </p>
                  </div>
                  <div>
                    <h5 className="fs_20 fw-semibold ff_opne text-white mb-1">
                      {userDetails?.following_count}
                    </h5>
                    <p className="fs_16 fw-normal ff_opne text-white mb-0">
                      Following
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHero;
