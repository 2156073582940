import React from "react";
import AllVideoHeroComponent from "../Components/AllVideosPage/AllVideoHeroComponent";
import VideoDetailsComponent from "../Components/AllVideosPage/VideoDetailsComponent";
import { useState } from "react";

const AllVideo = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const [showAllVideoHero, setShowAllVideoHero] = useState(true);

  const handleTryButtonClick = (videoData) => {
    setSelectedVideo(videoData);
    setShowAllVideoHero(false); // Hide AllVideoHeroComponent
  };
  return (
    <div>
      {showAllVideoHero && (
        <AllVideoHeroComponent
          selectedVideo={selectedVideo}
          onTryButtonClick={handleTryButtonClick}
        />
      )}
      {selectedVideo && <VideoDetailsComponent videoData={selectedVideo} />}
    </div>
  );
};

export default AllVideo;
