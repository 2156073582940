import { axiosInstance } from "./Common/AxiosInstanc";
import { decryptResponse } from "./Common/helper";
export const getVideo = async (userId) => {
  const response = await axiosInstance({
    url: `user_video/${userId}`,
    method: "GET",
  });
  if (response.status === 200) {
    if (response.data.data) {
      const key = localStorage.getItem("aax");
      const re = decryptResponse(key, response?.data?.data);
      return re;
    } else {
      return [];
    }
  }
};
