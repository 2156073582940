import axios from "axios";
import { getToken } from "./AuthApi";

export const uploadWebVfx = async (data) => {
  try {
    const token = await getToken(); // Ensure you are getting the token dynamically

    // Initial request that might result in a 302 redirect
    const response = await axios.post(
      "https://storage.trotterit.com/v2/web_vfx/request",
      data,
      {
        headers: {
          "x-access-token": token,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("first", response);
    const url = response.data.data;
    window.open(url);
  } catch (error) {
    console.error("Error:", error);
  }
};
