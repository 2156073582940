import { configureStore } from "@reduxjs/toolkit";
import TrendingReducer from "./Trending/TrendingReducer";
import AivideoReducer from "./AiVideo/AivideoReducer";
import StoryDetailsReducer from "./StoryDetails/StoryDetailsReducer";
import userDetailsReducers from "./userDetails/userDetailsReducers";
import RotationLogo from "./RotationLogo/RotationLogo";
import SearchSlice from "./Search/SearchSlice";
import BlogReducer from "./Blog/BlogReducer";

const store = configureStore({
  reducer: {
    User : userDetailsReducers,
    Logo : RotationLogo,
    Trending: TrendingReducer,
    AIVideo : AivideoReducer,
    StoryDetails : StoryDetailsReducer,
    SearchDetails : SearchSlice,
    Blog : BlogReducer
  },
});
export default store;
