import { axiosInstance } from "./Common/AxiosInstanc";
import { decryptResponse } from "./Common/helper";

export const getAiTemplate = async () => {
  const res = await axiosInstance({
    url: `trip_templates`,
    method: "GET",
  });

  if (res.status === 200) {
    const key = localStorage.getItem("aax");
    const re = decryptResponse(key, res?.data?.data);
    return re;
  }
};
