import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker, Polyline } from "google-maps-react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StoryDetailsReducer, { fetchStoryHaltDetails } from "../../Redux/StoryDetails/StoryDetailsReducer";
const MapConatiner = (props) => {
  const { id } = useParams()
  const dispatch = useDispatch(StoryDetailsReducer);
  var media_url = "";
  const haltData = useSelector((state) => state.StoryDetails.halt);
  const [haltdata, setHaltData] = useState(haltData);
  const [polylineData, setPolylineData] = useState([]);
  const [bound, setBound] = useState(null);

  const setHalt = async () => {
    media_url = "https://storage.trotterit.com/images/halt/";
    let polydata = [];
    for (var i = 0; i < haltData?.length; i++) {
      var obj2 = {
        lat: parseFloat(haltData[i]?.lat),
        lng: parseFloat(haltData[i]?.lon),
      };
      await polydata.push(obj2);
    }

    var first_obj = {
      lat: parseFloat(haltData[0]?.lat),
      lng: parseFloat(haltData[0]?.lon),
    };
    await setPolylineData(polydata);

    await polydata.push(first_obj);
  };

  const handleMapLoad = () => {
    const bounds = new props.google.maps.LatLngBounds();
    for (let loc of haltData)
      bounds.extend({ lat: parseFloat(loc.lat), lng: parseFloat(loc.lon) });
    setBound(bounds);
  };

  useEffect(()=>{
    if(haltData.length > 0){
      handleMapLoad()
    }
  },[haltData])
  useEffect(() => {
    setHaltData(haltData)
    setHalt();
  }, [haltData]);

  return (
    <div className="map-container">
      {haltData.length > 0 &&
        <Map
          containerStyle={{
            width: "100%",
            height: "100%",
          }}
          onReady={handleMapLoad}
          className={"map"}
          google={props?.google}
          zoom={18}
          initialCenter={{ lat: 48.1327673, lng: 4.1753323 }}
          bounds={bound}
          mapType="satellite"
          zoomControl={false}
          mapTypeControl={false}
          fullscreenControl={false}
          panControl={false}
          streetViewControl={false}
          mapTypeControlOptions={false}
          disableDefaultUI={true}
        >
          {haltdata.length > 0 &&
            haltdata?.map((loc, i) => {
              const CircularMarker = ({ imageUrl }) => (
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <img
                    src={imageUrl}
                    alt="Marker"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              );
              const getMarkerImage = (i) => {
                const imageUrl =
                  i === 0
                    ? "https://storage.trotterit.com/images/halt/" +
                    props?.data[1]?.media[0]?.media
                    : "https://storage.trotterit.com/images/halt/" +
                    haltdata[i]?.media[0]?.media;

                return {
                  icon: {
                    url: imageUrl,
                    scaledSize: new props.google.maps.Size(40, 40),
                    origin: new props.google.maps.Point(0, 0),
                    anchor: new props.google.maps.Point(20, 20),
                    labelOrigin: new props.google.maps.Point(20, 20),
                  },
                  position: {
                    lat: parseFloat(haltdata[i]?.lat),
                    lng: parseFloat(haltdata[i]?.lon),
                  },
                  map: props.map,
                  key: i,
                  children: <CircularMarker imageUrl={imageUrl} />,
                };
              };

              return (
                <Marker
                  key={i}
                  position={{
                    lat: parseFloat(loc.lat),
                    lng: parseFloat(loc.lon),
                  }}
                  {...getMarkerImage(i)}
                />
              );
            })}
          <Polyline
            path={polylineData}
            options={{
              strokeColor: "white",
              strokeOpacity: 0.8,
              strokeWeight: 0,
              icons: [
                {
                  icon: {
                    path: "M 0,-1 0,1",
                    strokeOpacity: 1,
                    scale: 2,
                  },
                  offset: "0",
                  repeat: "15px",
                },
              ],
            }}
          />
        </Map>
      }
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyC8FHSpqqDELhnumtiCqWs0BWYbkJRfXM8",
})(MapConatiner);
