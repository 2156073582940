import React from "react";
import exp1 from "../Assets/png/exp1.png";
import exp2 from "../Assets/png/exp2.png";
import exp3 from "../Assets/png/exp3.png";
import exp4 from "../Assets/png/exp4.png";

function Experinces() {
  return (
    <div className="bg-white">
      <div className="bg-white custom_container py-5">
        <div className="mx-md-4">
          <h2>Experiences (Coming Soon)</h2>
          <p className="fs-5 tst">
            We're thrilled to announce that we'll soon be launching a series of
            incredible experiences, each tailored to cater to your passions and
            curiosities. From indulging in delectable Food and Drinks
            adventures, to igniting your inner adventurer with Sports and
            thrilling escapades, embarking on unforgettable Sightseeing
            journeys, or delving deep into the world of Art and Culture – we've
            got it all covered. Stay tuned to embark on a remarkable journey
            with us, where you can book experiences that will make your every
            moment memorable."
          </p>
        </div>

        <div className=" row justify-content-center align-items-center">
          <div className="cardc col-md-3 col-6 pe-0">
            <img className="w-100" src={exp1} />
            <span className="text">Sightseeing</span>
          </div>
          <div className="cardc col-md-3 col-6 ps-0">
            <img className="w-100" src={exp2} />
            <span className="text">
              Art and <br /> Culture
            </span>
          </div>
          <div className="cardc col-md-3 col-6 ps-0">
            <img className="w-100" src={exp3} />
            <span className="text">
              {" "}
              Food and <br /> Drinks
            </span>
          </div>
          <div className="cardc col-md-3 col-6 ps-0">
            <img className="w-100" src={exp4} />
            <span className="text">
              Sports, and <br /> Adventure
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experinces;
