import React, { version } from "react";
import downgrass from "../Assets/png/downgrass.png";
import phone1 from "../Assets/png/mobile_viwe3.jpg";
import phone2 from "../Assets/png/Footprint Map.png";
import phone3 from "../Assets/png/AI Video.png";
import vector from "../Assets/png/Vector1.png";
import aeroplan1 from "../Assets/svg/aeroplane1.svg";
import aeroplan2 from "../Assets/svg/aeroplane2.svg";
import aeroplan3 from "../Assets/svg/aeroplane3.svg";
import aeroplan4 from "../Assets/svg/aeroplane4.svg";
import aeroplan5 from "../Assets/svg/aeroplane5.svg";
import aeroplan6 from "../Assets/svg/aeroplane6.svg";
import aeroplan7 from "../Assets/svg/aeroplane7.svg";
import Bottom from "../Assets/png/upgrass.png";
import { Link } from "react-router-dom";
function Features() {
  return (
    <div className="">
      <div className="bg-white feature">
        <img src={downgrass} className=" featureContainer w_full" />
        <h2 className="text-center fs_48 fw_semibold ff_opne color_gray1 display-4 fw-bold cursor-pointer">
          Features
        </h2>
        <div className="vector ">
          <div className="position-relative d-xl-block  d-none">
            <img className="w-100 h-100" src={vector} />
            <img className=" position-absolute aeroplan1" src={aeroplan1} />
            <img className=" position-absolute aeroplan2" src={aeroplan2} />
            <img className=" position-absolute aeroplan3" src={aeroplan3} />
            <img className=" position-absolute aeroplan4" src={aeroplan4} />
            <img className=" position-absolute aeroplan5" src={aeroplan5} />
            <img className=" position-absolute aeroplan6" src={aeroplan6} />
            <img className=" position-absolute aeroplan7" src={aeroplan7} />
          </div>
        </div>
        <div className="mx-auto phoneContainer">
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2000"
            data-aos-delay="300"
            className="d-md-flex align-items-center justify-content-around first"
          >
            <h3 className="fs_32 ff_opne color_gray1 text-center mb-1 d-md-none d-block">
              Digital Travel Journal
            </h3>
            <span className="fs_22 ff_opne color_gray3  text-center d-md-none d-block">
              "Track Relive and Share your travel Story"
            </span>
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="2000"
              data-aos-delay="300"
              className="phone mx-4 py-4 mt-3"
            >
              <div className="iphone">
                <img
                  src={phone2}
                  alt="phone"
                  className="mphone rounded-4"
                  srcset=""
                />
              </div>
            </div>
            <div className="textContainer">
              <h3 className="fs_32 ff_opne color_gray1 mb-1 d-md-block d-none">
                Digital Travel Journal
              </h3>
              <span className="fs_22 ff_opne color_gray3  d-md-block d-none">
                "Track Relive and Share your travel Story"
              </span>
              <p className="fs_16 ff_opne color_gray3 mt-3  mb-0">
                Trotter It is your personal digital travel journal that gives
                you the platform to create, document and share your beautiful
                travel stories.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2000"
            data-aos-delay="800"
            className="d-md-flex flex-row-reverse align-items-center justify-content-around"
          >
            <h3 className="fs_32 ff_opne text-center d-md-none d-block color_gray1 mb-1">
              Explorer
            </h3>
            <span className="fs_22 ff_opne text-center d-md-none d-block color_gray3 ">
              "Spend less time seeking recommendations and more time
              experiencing your travel"
            </span>
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="2000"
              data-aos-delay="800"
              className="phone py-4 mx-4 mt-3"
            >
              <div className="iphone">
                <img
                  src={phone1}
                  alt="phone"
                  className="mphone rounded-4"
                  srcset=""
                />
              </div>
            </div>
            <div className="textContainer">
              <h3 className="fs_32 d-md-block d-none ff_opne color_gray1 mb-1">
                Explorer
              </h3>
              <span className="fs_22 d-md-block d-none ff_opne color_gray3 ">
                "Spend less time seeking recommendations and more time
                experiencing your travel"
              </span>
              <p className="fs_16 ff_opne color_gray3 mt-3">
                With a click of a button, the app summarizes all travelers have
                experienced around you and brings it to you on a map. The
                ‘Explorer’ tells you all the places you can visit around you,
                where to eat and drink, and finally, the off-map places where
                like-minded travelers like yourself have made a pit stop. The
                result? Less time seeking recommendations during your travel,
                and more time experiencing the reality of travel.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2000"
            data-aos-delay="1500"
            className="d-md-flex gap-5 mt-5 mt-md-0 align-items-center justify-content-around"
          >
            <h3 className="fs_32 ff_opne color_gray1 mb-1 text-center d-md-none d-block ">
              A.I. Videos Of Your Trip
            </h3>
            <span className="fs_22 ff_opne color_gray3  text-center d-md-none d-block ">
              "Transform Your Memories Into Stunning Videos"
            </span>
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="2000"
              data-aos-delay="1500"
              className="phone mt-3"
            >
              <div className="iphone py-4">
                <img src={phone3} alt="phone" className="mphone" srcset="" />
              </div>
            </div>
            <div className="textContainer">
              <h3 className="fs_32 ff_opne color_gray1 mb-1 d-md-block d-none">
                A.I. Videos Of Your Trip
              </h3>
              <span className="fs_22 ff_opne color_gray3  d-md-block d-none">
                "Transform Your Memories Into Stunning Videos"
              </span>
              <p className="fs_16 ff_opne color_gray3 mt-3">
                By harnessing the power of artificial intelligence, Trotter It
                will give you the ability to transform your travel images and
                videos into a captivating recap videos set to music, giving you
                a seamless and exciting way to relive and share your trips.
              </p>
              <Link to="/allvideos">
                <button className="tryout_btn fs_16 fw-semibold ff_opne">
                  Try it Out
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/* <div className="mt-5">hjkl</div> */}
        {/* <div className="botomGrass">
          <img src={Bottom} width={"100%"} height={"100%"} />
        </div> */}
      </div>
    </div>
  );
}

export default Features;
