import React from "react";
import allvideo_image1 from "../../Assets/png/allvideo_image1.png";
import { useState } from "react";
import { getAiTemplate } from "../../Services/AITemplate";
import { useEffect } from "react";
import ReactPlayer from "react-player";
import VideoDetailsComponent from "./VideoDetailsComponent";
import video_img1 from "../../Assets/png/video_img1.png";
import cross_icon from "../../Assets/png/cross_icon.png";
import { useDispatch, useSelector } from "react-redux";
import AivideoReducer, { getAiVideo } from "../../Redux/AiVideo/AivideoReducer";

const AllVideoHeroComponent = ({ onTryButtonClick, selectedVideo }) => {
  const [allAiVideo, setAllAiVideo] = useState([]);
  const dispatch = useDispatch(AivideoReducer);
  const AiVideos = useSelector((state) => state.AIVideo.data);
  const gettemplate = async () => {
    setAllAiVideo(AiVideos);
    console.log(AiVideos);
  };

  useEffect(() => {
    dispatch(getAiVideo());
  }, []);

  useEffect(() => {
    gettemplate();
  }, [AiVideos]);

  const [showPopup, setShowPopup] = useState(false);
  function calculatediscount(discount_price, actual_price) {
    return Math.floor(((actual_price - discount_price) / actual_price) * 100);
  }

  useEffect(() => {
    const popupTimer = setTimeout(() => {
      setShowPopup(true);
    }, 2000);

    return () => {
      clearTimeout(popupTimer);
    };
  }, []);

  return (
    <div className="bg-white py-5 position-relative">
      {showPopup && (
        <div className="bg position-fixed start-0 top-0">
          <div className=" transition position-absolute custom_pop pt-5 mt-5 start-50 translate-middle">
            <div className="d-flex justify-content-center pt-3">
              <img
                onClick={() => setShowPopup(false)}
                className=" cursor-pointer"
                src={cross_icon}
                alt="cross_icon"
              />
            </div>
            <div className="popup-section mt-3">
              <img className="w-100" src={video_img1} alt="video_img1" />
              <h4 className=" fs_24 ff_opne  text-center mt-3 fw-semibold">
                Welcome to Trotter It's AI Videos. Here's a quick guide:
              </h4>
              <div className="d-flex justify-content-center flex-wrap text-center gap-3">
                <p>
                  <span className=" fw-semibold">Step 1:</span> Select a
                  <br></br>
                  Template
                </p>
                <p>
                  <span className="fw-semibold">Step 2:</span> Purchase
                  <br></br> Your Template
                </p>
                <p>
                  <span className=" fw-semibold">Step 3:</span> Add Your
                  <br></br> Media
                </p>
                <p>
                  <span className=" fw-semibold">Step 4:</span> Share Your
                  <br></br>
                  Contact Details
                </p>
                <p>
                  <span className=" fw-semibold">Step 5:</span> Sit back and
                  <br></br>
                  relax & experience the Magic
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="custom_container">
        <h4 className=" fs_48 ff_opne color_gray1 text-center mb-0">
          AI Videos
        </h4>
        <p className=" fs_24 ff_opne color_gray3 text-center pt-2 mt-1 mb-0">
          Discover the Hottest Travel Destinations and Stories
        </p>
        <div className="row mt-4">
          {allAiVideo.map((item, index) => (
            <div key={index} className="col-md-4 col-sm-6 mt-4">
              <div className="allvideo_box h-100">
                <div className="position-relative">
                  <div className="w-100 h-100 ">
                    <ReactPlayer
                      url={
                        "https://storage.trotterit.com/videos/template/" +
                        item?.media
                      }
                      pip={true}
                      controls={false}
                      playing={true}
                      loop
                      muted={true}
                      playsinline={true}
                      className="w-100 rounded-3 overflow-hidden video_card"
                    />
                  </div>
                  <div className="position-absolute video_content">
                    <p className=" fs_16 ff_opne fw-semibold text-white ">
                      {item.title}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center mt-3 justify-content-between">
                    <div className="">
                      <div className="d-flex gap-2 align-items-center">
                        <p className="mb-0 fs_22 color_gray1 ff_opne fw-semibold">
                          ₹{" "}
                          {item?.discount_price !== 0
                            ? item?.discount_price
                            : item.actual_price}
                        </p>
                        {item?.discount_price !== 0 && (
                          <button className="off_btn fs-6 text-white">
                            {calculatediscount(
                              item?.discount_price,
                              item.actual_price
                            )}{" "}
                            % Off
                          </button>
                        )}
                      </div>

                      {item?.discount_price !== 0 && (
                        <div className="mt-1">
                          <p className="mb-0 color_gray1 ff_opne">
                            <del className="fs-5 fw-medium del">
                              ₹ {item.actual_price}
                            </del>{" "}
                          </p>
                        </div>
                      )}
                    </div>
                    <button
                      onClick={() => {
                        onTryButtonClick(item);
                      }}
                      className="try_btn fs_16 fw-semibold ff_opne text-white"
                    >
                      Try
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllVideoHeroComponent;
