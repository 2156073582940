import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker, Polyline } from "google-maps-react";
import { useSelector } from "react-redux";
const MapConatiner = (props) => {
  const [bound, setBound] = useState(null);
  const searchData = useSelector((state) => state.SearchDetails.data);
  const [polylineData, setPolylineData] = useState([]);
  const [haltdata, setHaltData] = useState([]);
  var media_url = "";

  useEffect(() => {
    setHaltData((prevHaltData) => [
      ...searchData.map((element) => element?.halt_info),
    ]);
  }, [searchData]);

  const setHalt = async () => {
    media_url = "https://storage.trotterit.com/images/halt/";
    let polydata = [];
    for (var i = 0; i < haltdata.length; i++) {
      var obj2 = {
        lat: parseFloat(haltdata[i]?.lat),
        lng: parseFloat(haltdata[i]?.lon),
      };
      await polydata.push(obj2);
    }

    var first_obj = {
      lat: parseFloat(haltdata[0]?.lat),
      lng: parseFloat(haltdata[0]?.lon),
    };
    await setPolylineData(polydata);

    await polydata.push(first_obj);
  };

  const handleMapLoad = () => {
    const bounds = new props.google.maps.LatLngBounds();
    for (let loc of haltdata)
      bounds.extend({ lat: parseFloat(loc.lat), lng: parseFloat(loc.lon) });
    setBound(bounds);
  };

  useEffect(() => {
    setHalt();
    handleMapLoad();
  }, [haltdata]);

  return (
    <div className="mapContainer">
      <Map
        containerStyle={{
          width: "100%",
          height: "100%",
        }}
        onReady={handleMapLoad}
        className={"mapSearch"}
        google={props?.google}
        zoom={3}
        initialCenter={{ lat: 48.1327673, lng: 4.1753323 }}
        bounds={bound}
        mapType="satellite"
        zoomControl={false}
        mapTypeControl={false}
        fullscreenControl={false}
        panControl={false}
        streetViewControl={false}
        mapTypeControlOptions={false}
        disableDefaultUI={true}
      >
        {haltdata.length > 0 &&
          haltdata?.map((loc, i) => {
            const imageUrl =
              i === 0
                ? "https://storage.trotterit.com/images/halt/" +
                  haltdata[0]?.media[0]?.media
                : "https://storage.trotterit.com/images/halt/" +
                  haltdata[i]?.media[0]?.media;
            let iconMarker = new window.google.maps.MarkerImage(
              imageUrl,
              null /* size is determined at runtime */,
              null /* origin is 0,0 */,
              null /* anchor is bottom center of the scaled image */,
              new window.google.maps.Size(32, 32)
            );
            
            return (
              <Marker
                icon={iconMarker}
                position={{
                  lat: parseFloat(loc.lat),
                  lng: parseFloat(loc.lon),
                }}
              ></Marker>
            );
          })}
        <Polyline
          path={polylineData}
          options={{
            strokeColor: "white",
            strokeOpacity: 0.8,
            strokeWeight: 0,
            icons: [
              {
                icon: {
                  path: "M 0,-1 0,1",
                  strokeOpacity: 1,
                  scale: 2,
                },
                offset: "0",
                repeat: "15px",
              },
            ],
          }}
        />
      </Map>
      <div className="mapoverloday"></div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyC8FHSpqqDELhnumtiCqWs0BWYbkJRfXM8",
})(MapConatiner);
