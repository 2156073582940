import { axiosInstance } from "./Common/AxiosInstanc";
import { decryptResponse } from "./Common/helper";

export const getStories = async (index) => {
  const response = await axiosInstance({
    url: `admin/trips?index=${index}`,
    method: "GET",
  });
  if (response.status === 200) {
    const key = localStorage.getItem("aax");
    const re = decryptResponse(key, response?.data?.data);
    return re;
  }
};

export const getStoriesDetailsbyId = async (id) => {
  const res = await axiosInstance({
    url: `get_trip_details/${id}`,
    method: "GET",
  });

  if (res.status === 200) {
    const key = localStorage.getItem("aax");
    const re = decryptResponse(key, res?.data?.data);
    return re;
  }
};

export const get_haltDetails_by_id = async (id) => {
  const res = await axiosInstance({
    url: `get_trip_halts/${id}`,
    method: "GET",
  });

  if (res.status === 200) {
    const key = localStorage.getItem("aax");
    const re = decryptResponse(key, res?.data?.data);
    return re;
  }
};
