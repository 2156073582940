import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WithinGeo } from "../../Services/GeoSearch/GeoSearch";
const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchSearchDetails = createAsyncThunk(
  "/getSearchDetails",
  async (id) => {
    try {
      const data = await WithinGeo(id);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const SearchSlice = createSlice({
  name: "Trending",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSearchDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSearchDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default SearchSlice.reducer;
