import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllBlogs, singleBlogDetail } from "../../Services/Blog/BlogServices";

const initialState = {
  blogs: [],
  blogDetails : [],
  loading: false,
  error: null,
};

export const fetchAllBogs = createAsyncThunk(
  "/getAllBlogs",
  async () => {
    try {
      const data = await getAllBlogs();
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchblogDetails = createAsyncThunk(
  "/getblogdetails",
  async (id) => {
    try {
      const data = await singleBlogDetail(id);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const StoryDetailsSlice = createSlice({
  name: "Blogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllBogs.fulfilled, (state, action) => {
        state.loading = false;
        state.blogs = action.payload;
      })
      .addCase(fetchAllBogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(fetchblogDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchblogDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.blogDetails = action.payload;
      })
      .addCase(fetchblogDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default StoryDetailsSlice.reducer;
