import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getImageURL, removeUnwanted, replaceSpaceWithHyphen } from "../../Services/Common/helper";
import eye from "../../Assets/png/Vector 8.png";
import track from "../../Assets/png/Vector 9.png";
import { useDispatch, useSelector } from "react-redux";
import TrendingReducer, {
  fetchTrendingTrips,
} from "../../Redux/Trending/TrendingReducer";
const TrendingCard = () => {
  const [trendingTrips, setTrendingTrips] = useState([]);
  const TrendingTirps = useSelector((state) => state.Trending.data);
  const [pageCount, setPageCount] = useState(0);
  const dispatch = useDispatch(TrendingReducer);
  const navigate = useNavigate();
  const getTranding = async () => {
    setTrendingTrips(TrendingTirps);
  };

  function LoadMore() {
    setPageCount(pageCount + 1);
    window.scrollTo({
      top: 280,
    });
  }

  function Less() {
    setPageCount(pageCount - 1);
    window.scrollTo({
      top: 280,
    });
  }

  useEffect(() => {
    dispatch(fetchTrendingTrips(pageCount));
  }, [pageCount]);

  useEffect(() => {
    getTranding();
  }, [TrendingTirps]);
  return (
    <>
      <div className="bg-white pb-4">
        <div className="container custom_container">
          <div className="row justify-content-start">
            {trendingTrips?.map((items, index) => {
              return (
                <>
                  <div
                    className="card-box col-md-4 col-sm-6 position-relative mt-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/stories/${items._id}/${replaceSpaceWithHyphen(items?.user_name)}/${replaceSpaceWithHyphen(items.title)}/`);
                    }}
                  >
                    <div className="tripimg">
                      <img
                        src={
                          "https://storage.trotterit.com/images/trip/" +
                          items.image
                        }
                        className="cardimg"
                      />
                    </div>
                    <div className="position-absolute top-0 tripdetails p-3 w-100 pe-3">
                      <div className="d-flex flex-column justify-content-between h-100 pe-4">
                        <div className="d-flex gap-2">
                          <Link to="#">
                            <div className="userPic">
                              <img
                                src={getImageURL(items.profile_pic)}
                                className="cardimg"
                              />
                            </div>
                          </Link>
                          <div>
                            <span className="text-white">
                              {items.user_name}
                            </span>
                          </div>
                        </div>
                        <div>
                          <h3 className="text-white">
                            {removeUnwanted(items.title)}
                          </h3>
                        </div>
                        <div className="d-flex justify-content-between text-white">
                          <div className="d-flex flex-column align-items-center">
                            <span className="text-white">
                              {items.view_count}
                            </span>
                            <div>
                              <img src={eye} alt="" srcset="" />
                            </div>
                          </div>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={track} />
                            </div>
                            <div>
                              <p>
                                {items.halt_count} Footprints{" "}
                                {Math.round(items.total_distance)} Km
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            <div className="d-flex justify-content-center mt-4 gap-3">
              <div
                className="btn signup fs_16 fw-semibold ff_opne"
                onClick={LoadMore}
              >
                Load More
              </div>
              {pageCount != 0 && (
                <div
                  className="btn signup fs_16 fw-semibold ff_opne"
                  onClick={Less}
                >
                  Less
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrendingCard;
