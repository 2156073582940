import React from "react";
import ProfileHero from "../Components/profilePage/ProfileHero";
import TabsComponent from "../Components/profilePage/TabsComponent";

const ProfilePage = () => {
  return (
    <div>
      <ProfileHero />
      <TabsComponent />
    </div>
  );
};

export default ProfilePage;
