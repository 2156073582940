import { axiosInstance } from "./Common/AxiosInstanc";
import { decryptResponse } from "./Common/helper";

export const getCounrty = async (country) => {
  const res = await axiosInstance({
    url: `/user_video/category/${country}`,
    method: "GET",
  });
  if (res.status === 200) {
    const key = localStorage.getItem("aax");
    const re = decryptResponse(key, res?.data?.data);
    return re;
  }
};

export const getCounrtyTrips = async (country) => {
  const res = await axiosInstance({
    url: `category_trip/${country}`,
    method: "GET",
  });
  if (res.status === 200) {
    const key = localStorage.getItem("aax");
    const re = decryptResponse(key, res?.data?.data);
    return re;
  }
};
