import React from "react";
import StoryCard from "../Components/StoryCard/StoryCard";
import MapConatiner from "../Components/Map/Map";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StoryDetailsReducer, {
  fetchStoryHaltDetails,
} from "../Redux/StoryDetails/StoryDetailsReducer";

function StoryDetail() {
  const { id } = useParams();
  const [halt, setHaltData] = useState([]);
  const dispatch = useDispatch(StoryDetailsReducer);
  const haltDetails = useSelector((state) => state.StoryDetails.halt);

  useEffect(() => {
    dispatch(fetchStoryHaltDetails(id));
    window.scrollTo("top", 0);
  }, []);

  const GethaltDetails = async () => {
    setHaltData(haltDetails);
  };

  useEffect(() => {
    GethaltDetails();
  }, [haltDetails]);

  return (
    <div className="bg-white">
      <div className="d-flex flex-column justify-content-between gap-3 p-3 flex-md-row flex-column-reverse bg-white">
        <div>
          <StoryCard />
        </div>
        <div>{halt?.length > 0 && <MapConatiner data={halt} />}</div>
      </div>
    </div>
  );
}

export default StoryDetail;
