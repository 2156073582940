
import CryptoJS from "crypto-js";

export const getImageURL = (imageURL) => {
  if (imageURL?.startsWith("https://")) {
    return imageURL;
  }
  const baseURL = "https://storage.trotterit.com/images/profile/";

  return baseURL + imageURL;
};

export const removeUnwanted = (desc) => {
  if (desc !== undefined && desc != null) {
    return unescape(decodeURI(desc.replace(/\+|,/g, " ")));
  }
};

export const countryFlag = [
  { key: "AF", Title: "afghanistan" },
  { key: "AL", Title: "albania" },
  { key: "DZ", Title: "algeria" },
  { key: "AX", Title: "aland-islands" },
  { key: "AS", Title: "american-samoa" },
  { key: "AD", Title: "andorra" },
  { key: "AO", Title: "angola" },
  { key: "AI", Title: "anguilla" },
  { key: "AG", Title: "antigua-and-barbuda" },
  { key: "AR", Title: "argentina" },
  { key: "AM", Title: "armenia" },
  { key: "AW", Title: "aruba" },
  { key: "AU", Title: "australia" },
  { key: "AT", Title: "austria" },
  { key: "AZ", Title: "azerbaijan" },
  { key: "BS", Title: "bahamas" },
  { key: "BH", Title: "bahrain" },
  { key: "BD", Title: "bangladesh" },
  { key: "BB", Title: "barbados" },
  { key: "BY", Title: "belarus" },
  { key: "BZ", Title: "belize" },
  { key: "BJ", Title: "benin" },
  { key: "BM", Title: "bermuda" },
  { key: "BE", Title: "belgium" },
  { key: "BT", Title: "bhutan" },
  { key: "BO", Title: "bolivia" },
  { key: "BA", Title: "bosnia-and-herzegovina" },
  { key: "BW", Title: "botswana" },
  { key: "BR", Title: "brazil" },
  { key: "IO", Title: "british-indian-ocean-territory" },
  { key: "VG", Title: "british-virgin-islands" },
  { key: "BN", Title: "brunei" },
  { key: "BG", Title: "bulgaria" },
  { key: "BF", Title: "burkina-faso" },
  { key: "BI", Title: "burundi" },
  { key: "KH", Title: "cambodia" },
  { key: "CM", Title: "cameroon" },
  { key: "CA", Title: "canada" },
  { key: "CV", Title: "cape-verde" },
  { key: "KY", Title: "cayman-islands" },
  { key: "CF", Title: "central-african-republic" },
  { key: "TD", Title: "chad" },
  { key: "CL", Title: "chile" },
  { key: "CN", Title: "china" },
  { key: "CX", Title: "christmas-island" },
  { key: "CC", Title: "cocos-island" },
  { key: "CO", Title: "colombia" },
  { key: "KM", Title: "comoros" },
  { key: "CK", Title: "cook-islands" },
  { key: "CR", Title: "costa-rica" },
  { key: "HR", Title: "croatia" },
  { key: "CU", Title: "cuba" },
  { key: "CY", Title: "cyprus" },
  { key: "CZ", Title: "czech-republic" },
  { key: "CD", Title: "democratic-republic-of-congo" },
  { key: "DK", Title: "denmark" },
  { key: "DJ", Title: "djibouti" },
  { key: "DM", Title: "dominica" },
  { key: "DO", Title: "dominican-republic" },
  { key: "EC", Title: "ecuador" },
  { key: "EG", Title: "egypt" },
  { key: "GB", Title: "england" },
  { key: "ER", Title: "eritrea" },
  { key: "EE", Title: "estonia" },
  { key: "ET", Title: "ethiopia" },
  { key: "FK", Title: "falkland-islands" },
  { key: "FO", Title: "faroe-islands" },
  { key: "FJ", Title: "fiji" },
  { key: "FI", Title: "finland" },
  { key: "FR", Title: "france" },
  { key: "PF", Title: "french-polynesia" },
  { key: "GA", Title: "gabon" },
  { key: "GM", Title: "gambia" },
  { key: "GE", Title: "georgia" },
  { key: "DE", Title: "germany" },
  { key: "GH", Title: "ghana" },
  { key: "GI", Title: "gibraltar" },
  { key: "GR", Title: "greece" },
  { key: "GL", Title: "greenland" },
  { key: "GD", Title: "grenada" },
  { key: "GU", Title: "guam" },
  { key: "GT", Title: "guatemala" },
  { key: "GG", Title: "guernsey" },
  { key: "GW", Title: "guinea-bissau" },
  { key: "GN", Title: "guinea" },
  { key: "GY", Title: "guyana" },
  { key: "HT", Title: "haiti" },
  { key: "HN", Title: "honduras" },
  { key: "HK", Title: "hong-kong" },
  { key: "HU", Title: "hungary" },
  { key: "IS", Title: "iceland" },
  { key: "IN", Title: "india" },
  { key: "ID", Title: "indonesia" },
  { key: "IR", Title: "iran" },
  { key: "IQ", Title: "iraq" },
  { key: "IE", Title: "ireland" },
  { key: "IM", Title: "isle-of-man" },
  { key: "IL", Title: "israel" },
  { key: "IT", Title: "italy" },
  { key: "CI", Title: "ivory-coast" },
  { key: "JM", Title: "jamaica" },
  { key: "JP", Title: "japan" },
  { key: "JO", Title: "jordan" },
  { key: "KZ", Title: "kazakhstan" },
  { key: "KE", Title: "kenya" },
  { key: "KI", Title: "kiribati" },
  { key: "KW", Title: "kuwait" },
  { key: "KG", Title: "kyrgyzstan" },
  { key: "LA", Title: "laos" },
  { key: "LV", Title: "latvia" },
  { key: "LB", Title: "lebanon" },
  { key: "LS", Title: "lesotho" },
  { key: "LR", Title: "liberia" },
  { key: "LY", Title: "libya" },
  { key: "LI", Title: "liechtenstein" },
  { key: "LT", Title: "lithuania" },
  { key: "LU", Title: "luxembourg" },
  { key: "MO", Title: "macao" },
  { key: "MG", Title: "madagascar" },
  { key: "MW", Title: "malawi" },
  { key: "MY", Title: "malaysia" },
  { key: "MV", Title: "maldives" },
  { key: "ML", Title: "mali" },
  { key: "MT", Title: "malta" },
  { key: "MH", Title: "marshall-island" },
  { key: "MR", Title: "mauritania" },
  { key: "MU", Title: "mauritius" },
  { key: "MX", Title: "mexico" },
  { key: "FM", Title: "micronesia" },
  { key: "MD", Title: "moldova" },
  { key: "MC", Title: "monaco" },
  { key: "MN", Title: "mongolia" },
  { key: "ME", Title: "montenegro" },
  { key: "MS", Title: "montserrat" },
  { key: "MA", Title: "morocco" },
  { key: "MZ", Title: "mozambique" },
  { key: "MM", Title: "myanmar" },
  { key: "NA", Title: "namibia" },
  { key: "NR", Title: "nauru" },
  { key: "NP", Title: "nepal" },
  { key: "NL", Title: "netherlands" },
  { key: "NZ", Title: "new-zealand" },
  { key: "NI", Title: "nicaragua" },
  { key: "NE", Title: "niger" },
  { key: "NG", Title: "nigeria" },
  { key: "NU", Title: "niue" },
  { key: "KP", Title: "north-korea" },
  { key: "KR", Title: "south-korea" },
  { key: "MP", Title: "northern-marianas-islands" },
  { key: "NO", Title: "norway" },
  { key: "OM", Title: "oman" },
  { key: "PK", Title: "pakistan" },
  { key: "PW", Title: "palau" },
  { key: "PS", Title: "palestine" },
  { key: "PA", Title: "panama" },
  { key: "PG", Title: "papua-new-guinea" },
  { key: "PY", Title: "paraguay" },
  { key: "PE", Title: "peru" },
  { key: "PH", Title: "philippines" },
  { key: "PT", Title: "portugal" },
  { key: "PR", Title: "puerto-rico" },
  { key: "QA", Title: "qatar" },
  { key: "RO", Title: "romania" },
  { key: "RU", Title: "russia" },
  { key: "RW", Title: "rwanda" },
  { key: "KN", Title: "saint-kitts-and-nevis" },
  { key: "WS", Title: "samoa" },
  { key: "SM", Title: "san-marino" },
  { key: "ST", Title: "sao-tome-and-principe" },
  { key: "SA", Title: "saudi-arabia" },
  { key: "SN", Title: "senegal" },
  { key: "RS", Title: "serbia" },
  { key: "SC", Title: "seychelles" },
  { key: "SG", Title: "singapore" },
  { key: "SK", Title: "slovakia" },
  { key: "SI", Title: "slovenia" },
  { key: "SB", Title: "solomon-islands" },
  { key: "SO", Title: "somalia" },
  { key: "SD", Title: "sudan" },
  { key: "ZA", Title: "south-africa" },
  { key: "ES", Title: "spain" },
  { key: "IK", Title: "sri-lanka" },
  { key: "SR", Title: "suriname" },
  { key: "SE", Title: "sweden" },
  { key: "CH", Title: "switzerland" },
  { key: "SY", Title: "syria" },
  { key: "TW", Title: "taiwan" },
  { key: "TJ", Title: "tajikistan" },
  { key: "TZ", Title: "tanzania" },
  { key: "TH", Title: "thailand" },
  { key: "TG", Title: "togo" },
  { key: "TK", Title: "tokelau" },
  { key: "TO", Title: "tonga" },
  { key: "TT", Title: "trinidad-and-tobago" },
  { key: "TN", Title: "tunisia" },
  { key: "TR", Title: "turkey" },
  { key: "TM", Title: "turkmenistan" },
  { key: "TC", Title: "turks-and-caicos" },
  { key: "TV", Title: "tuvalu" },
  { key: "UG", Title: "uganda" },
  { key: "UA", Title: "ukraine" },
  { key: "UAE", Title: "united-arab-emirates" },
  { key: "US", Title: "united-states-of-america" },
  { key: "USA", Title: "united-states-of-america" },
  { key: "UY", Title: "uruguay" },
  { key: "UZ", Title: "uzbekistn" },
  { key: "VU", Title: "venezuela" },
  { key: "VN", Title: "vietnam" },
  { key: "VI", Title: "virgin-islands" },
  { key: "EH", Title: "western-sahara" },
  { key: "YE", Title: "yemen" },
  { key: "ZM", Title: "zambia" },
  { key: "ZW", Title: "zimbabwe" },
];

// var key = 'B&E)H@McQfThWmZq';

export const decryptResponse = (key, encryptedData) => {
  key = key.toString().replace("HARMAN", "").replace("GILL", "");
  var bytes = CryptoJS.AES.decrypt(encryptedData, key);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

export const replaceSpaceWithHyphen = (desc) => {
  if (desc !== undefined && desc != null) {
    return unescape(decodeURI(desc.replace(/[^a-zA-Z0-9\s\+]/g, "").replace(/ |\+/g, "-")));
  }
};

