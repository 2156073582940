import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: 0 };
const LogoColorSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    dark(state) {
      state.value++;
    },
    white(state) {
      state.value--;
    },
  },
});

export const { dark, white } = LogoColorSlice.actions;
export default LogoColorSlice.reducer;
