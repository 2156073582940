import React from "react";
import asia from "../../../Assets/img/Rectangle 750.jpg";
import europe from "../../../Assets/img/Rectangle 751.jpg";
import africa from "../../../Assets/img/Rectangle 752.jpg";
import america from "../../../Assets/img/Rectangle 748.jpg";
import australia from "../../../Assets/img/Rectangle 749.jpg";
import hart_icon from "../../../Assets/svg/hart_icon.svg";
import icon from "../../../Assets/svg/icon1.svg";
import line_image from "../../../Assets/svg/line_image.svg";
import { useEffect } from "react";
import { getCounrtyTrips } from "../../../Services/CountryService";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getImageURL, replaceSpaceWithHyphen } from "../../../Services/Common/helper";
function CountrySection() {
  const [country, setCountry] = useState("");
  const [trips, setTrips] = useState([]);
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState("");
  const { countryName } = useParams();
  const getCountryTrips = async (c) => {
    const res = await getCounrtyTrips(c);
    setTrips(res);
  };

  useEffect(() => {
    setCountry(countryName);
  }, []);

  useEffect(() => {
    if (country) {
      getCountryTrips(country);
    }
    switch (country) {
      case "asia":
        setCurrentImage(asia);
        break;
      case "europe":
        setCurrentImage(europe);

        break;
      case "africa":
        setCurrentImage(africa);

        break;
      case "america":
        setCurrentImage(america);
        break;
      case "australia":
        setCurrentImage(australia);
        break;
      default:
        break;
    }
  }, [country]);
  return (
    <div className="mx-md-5 mx-3 mb-5 pt-4">
      <div>
        <div>
          <div className="CountryModalImg position-relative">
            <img src={currentImage} alt="" />
            <div className="position-absolute bottom-0 left-3">
              <span className="text-white ff_opne text-size-md text-uppercase">
                {country}
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-5 cursor-pointer">
          {trips?.length > 0 ? (
            <>
              {trips?.map((obj, i) => (
                <>
                  <div
                    className="col-lg-4 col-sm-6 mt-4"
                    onClick={() => {
                      navigate(`/stories/${obj._id}/${replaceSpaceWithHyphen(obj.user_name)}/${replaceSpaceWithHyphen(obj.title)}`);
                    }}
                  >
                    <div key={i} className="profile_card">
                      <div className="position-relative">
                        <div className="tripimg1">
                          <img
                            src={
                              "https://storage.trotterit.com/images/trip/" +
                              obj.image
                            }
                            className="cardimg w-100"
                          />
                        </div>
                        <div className=" position-absolute content_position">
                          <div className="d-flex  gap-3">
                            <div className="userPic">
                              <img
                                src={getImageURL(obj.profile_pic)}
                                className="cardimg"
                              />
                            </div>
                            <div pt-2>
                              <h5 className=" fs_20 fw-semibold ff_opne text-white">
                                {obj.user_name}
                              </h5>
                              <p className=" fs_18 ff_opne text-white">
                                {obj.user_place}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="position-absolute icon_position">
                          <div className="d-flex gap-2 align-items-center">
                            <img src={hart_icon} alt="hart_icon" />
                            <p className="mb-0 fs_16 fw-normal ff_opne text-white">
                              {obj.halt_count} Likes
                            </p>
                          </div>
                          <div className="d-flex gap-2 mt-2 align-items-center">
                            <img src={icon} alt="hart_icon" />
                            <p className="mb-0 fs_16 fw-normal ff_opne text-white">
                              {obj?.view_count}
                            </p>
                          </div>
                        </div>
                        <div className=" position-absolute line_absolute">
                          <img src={line_image} alt="line_image" />
                          <p className="mb-0 fs_18 fw-semibold mt-2 ff_opne text-white">
                            {obj?.halt_count} Footprints{" "}
                            {Math.round(obj?.total_distance)} Kms
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <span className="text-white ff_opne text-size-sm text-center text-uppercase">
              No Trips Avaliable for this Country
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default CountrySection;
