import React from "react";
import EditorChoice from "../Components/Footprints/EditorChoice";
import MapSearchContainer from "../Components/SearchMap/MapSection";
import SearchInput from "../Components/Search/SearchInput";
import FootPrints from "../Components/FootPrints";

const FootprintPage = () => {
  return (
    <div>
      <div className="position-relative">
        <div className="position-absolute z_33 d-flex w-100 justify-content-center mt-5">
          <SearchInput />
        </div>
        <MapSearchContainer />
      </div>
      {/* <div className="">
        <EditorChoice />
      </div> */}
      <div>
        <FootPrints />
      </div>
    </div>
  );
};

export default FootprintPage;
