import React, { useState } from "react";
import FooterImg from "../Assets/svg/hero.webp";
import Logo from "../Assets/svg/logo.svg";
import FooterPhone from "../Assets/png/footerPhone.png";
import Googlplay from "../Assets/svg/googlePlay.svg";
import AppStore from "../Assets/svg/appStore.svg";
import fb from "../Assets/png/facebook.svg";
import insta from "../Assets/png/instagram.svg";
import pin from "../Assets/png/pin.png";
import twitter from "../Assets/png/twitter.png";
import linkedin from "../Assets/png/linkedin.avif";
import youtube from "../Assets/png/youtube.png";
import { Col, Row } from "react-bootstrap";
import DeleteProfile from "./Modals/DeleteProfile";
import { Link } from "react-router-dom";
function Footer() {
  const [handeDeleteModal, setDeteleModal] = useState(false);
  return (
    <>
      {/* <div className="footerContainer">
        <div className="footerUperContainer">
          <div className="footerOverlay"></div>
          <div className="FooterImage">
            <img
              src={FooterImg}
              width={"100%"}
              height={"100%"}
              className="imge"
            />
          </div>
          <div className="footerUperConatinerData">
            <div className="d-flex ">
              <div className="text-white d-flex gap-3 flex-column text-box text-start justify-content-center">
                <img src={Logo} width={"150px"} />
                <h1 className="display-3">Join the Adventure!</h1>
                <p className="w-50 fs-5 sub">
                  Become part of an incredible community of travelers. Document
                  your travel journeys and connect with like-minded explorers."
                </p>
                <div className="d-flex gap-3">
                  <img src={Googlplay} width={"200px"} />
                  <img src={AppStore} width={"200px"} />
                </div>
              </div>
              <div className="phoneImage">
                <img
                  src={FooterPhone}
                  className=""
                  height={"100%"}
                  width={"100%"}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className=" bg_green  py-5">
        <div className=" custom_container">
          <Row className="justify-content-between">
            <div className=" col-xl-3">
              <img src={Logo} width={"150px"} />
              <div>
                <p className="text-white opacity_50">
                  Trotter It<br></br> Fifth Floor<br></br> C 56/35 Sector 62
                  <br></br>
                  Noida, 201310 India.<br></br> Raghav@trotterit.com
                </p>
              </div>
            </div>

            <div className="col-xl-8 row gap-3">
              <div className=" col-1 d-xl-block d-none">
                <div className="divder "></div>
              </div>
              <div className=" col-lg-4 col-sm-6 ">
                <h5 className="text-white">About</h5>
                <ul className="ps-0 d-flex flex-column mt-2 pt-1">
                  <a
                    className=" text-decoration-none text-white opacity_50"
                    href="#"
                  >
                    Home
                  </a>
                  <a
                    className=" text-decoration-none pt-1 text-white opacity_50"
                    href="https://blog.trotterit.com/"
                  >
                    Blog
                  </a>
                  {/* <a
                    className=" text-decoration-none pt-1 text-white opacity_50"
                    href="#"
                  >
                    Careers
                  </a> */}
                  {/* <a
                    className=" text-decoration-none pt-1 text-white opacity_50"
                    href="https://trotterit.com/terms-conditions/ "
                  >
                    Terms & Policy
                  </a> */}
                  <Link
                    className=" text-decoration-none pt-1 text-white opacity_50"
                    to={"/privacy-policy"}
                  >
                    Privacy policy
                  </Link>
                  <Link
                    className=" text-decoration-none pt-1 text-white opacity_50"
                    to={"/Returns-and-Refunds"}
                  >
                    Returns & Refunds
                  </Link>
                  <Link
                    className=" text-decoration-none pt-1 text-white opacity_50"
                    to={"/Shipping-and-Delivery"}
                  >
                    Shipping and Delivery
                  </Link>
                  <Link
                    className=" text-decoration-none pt-1 text-white opacity_50"
                    to={"/terms-conditions"}
                  >
                    T&C
                  </Link>
                  <Link
                    className=" text-decoration-none pt-1 text-white opacity_50"
                    to={"/advertise"}
                  >
                    Advertise
                  </Link>

                  <div className="text-white opacity_50 ">
                    <div
                      className="pt-1 cursor-pointer"
                      onClick={() => {
                        setDeteleModal(true);
                      }}
                    >
                      Delete Account
                    </div>
                  </div>
                  {/* <a
                    className=" text-decoration-none pt-1 text-white opacity_50"
                    href="https://trotterit.com/privacy-policy/  "
                  >
                    Privacy policy
                  </a> */}
                </ul>
              </div>
              <div className=" col-lg-5 col-sm-6 ">
                <h5 className="text-white">Connect With Us</h5>
                <div className="d-flex gap-3 mt-3">
                  <div className="">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/TrotterIt/ "
                    >
                      <img
                        width={30}
                        height={30}
                        src={fb}
                        alt=""
                        srcset=""
                        className=""
                      />
                    </a>
                  </div>
                  <div className="">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/trotterit/"
                    >
                      {" "}
                      <img
                        width={30}
                        src={insta}
                        alt=""
                        srcset=""
                        className=""
                      />
                    </a>
                  </div>
                  {/* <div className="">
                    <img
                      width={30}
                      src={linkedin}
                      alt=""
                      srcset=""
                      className=""
                    />
                  </div> */}
                  <div className="">
                    <a
                      target="_blank"
                      href="https://www.youtube.com/channel/UC_uz_-biJ9mf3cmQSZE1-Bw"
                    >
                      <img
                        width={30}
                        src={youtube}
                        alt=""
                        srcset=""
                        className="rounded"
                      />
                    </a>
                  </div>
                  {/* <div className="">
                    <img
                      width={30}
                      src={twitter}
                      alt=""
                      srcset=""
                      className=""
                    />
                  </div>
                  <div className="">
                    <img
                      width30
                      width={30}
                      src={pin}
                      alt=""
                      srcset=""
                      className=""
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </Row>
          <div>
            <h6 className="text-white opacity_50">
              Copyright 2024 Trotterit | All Rights Reserved
            </h6>
          </div>
        </div>
        <DeleteProfile
          open={handeDeleteModal}
          close={() => {
            setDeteleModal(false);
          }}
        />
      </div>
    </>
  );
}

export default Footer;
