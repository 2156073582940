import React, { useEffect, useRef } from "react";
import BackgroundImage from "../Assets/svg/hero.webp";
import BackgroundTree from "../Assets/png/Group.png";
import Phone from "../Assets/svg/phone.webp";
import BottomTree from "../Assets/png/bottom.png";
import yourTrainImageSource from "../Assets/png/train.png";
import gsap from "gsap";
import logo from "../Assets/svg/logo.svg";
import image1 from "../Assets/svg/image1.svg";
import plane1 from "../Assets/png/plane1.png";
import plane2 from "../Assets/png/plane2.png";
// import ScrollTrigger from "gsap/ScrollTrigger";
import srchbtn from "../Assets/png/searchbtn.png";
import bottom_image from "../Assets/png/bottom.png";
import shadow_image from "../Assets/png/shdow_image.png";
import shadow_image1 from "../Assets/png/shdow_image1.png";
import group_image from "../Assets/svg/Group.png";
import group_image1 from "../Assets/png/mobile_viwe.png";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useNavigate } from "react-router-dom";
function Hero() {
  gsap.registerPlugin(ScrollTrigger);
  const trainImageRef = React.useRef(null);
  const planeRef = useRef(null);
  const textRef = useRef(null);
  const navigate = useNavigate()
  const animateTrainOnScroll = () => {
    const scrollY = window.scrollY;
    const movement = scrollY > 0 ? -800 : 0; // Adjust the value as needed
    const planeMovement = scrollY > 0 ? 800 : 0;
    const textMovement = scrollY > 0 ? 100 : 0;

    gsap.to(trainImageRef.current, {
      x: movement,
      ease: "power2.in",
      duration: 0.8, // Adjust the duration (in seconds) to make it slower
    });

    gsap.to(planeRef.current, {
      x: planeMovement,
      y: textMovement,
      ease: "power2.inOut",
      duration: 0.8, // Adjust the duration (in seconds) to make it slower
    });

    gsap.to(textRef.current, {
      y: textMovement,
      ease: "power2.inOut",
      duration: 0.8, // Adjust the duration (in seconds) to make it slower
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      animateTrainOnScroll();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const prevScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > prevScrollY.current) {
        animateTrainOnScroll();
      } else {
        animateTrainOnScroll();
      }

      prevScrollY.current = scrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const textContainer = document.querySelector(".text-trigger-container");
    gsap.from(textContainer, {
      y: 100,
      scrollTrigger: {
        trigger: textContainer,
        start: "top center",
        end: "center center",
        scrub: true,
      },
    });
  }, []);

  return (
    <>
      <div className="backgroundImageContainer position-relative">
        <img
          width={240}
          className="plan_ani1 position-absolute z_3  d-md-block d-none"
          src={shadow_image}
          alt="bottom_image"
        />
        <img
          width={240}
          className="plan_ani position-absolute z_3 end-0 d-md-block d-none"
          src={shadow_image1}
          alt="bottom_image"
        />
        <img
          className=" position-absolute w-100 z_index33"
          src={bottom_image}
          alt="bottom_image"
        />
        <div className="custom_container ">
          <div className="overlay start-0 ">
            <div className="position-absolute text_position z_index34">
              <div className="text-trigger-container pt-md-5" ref={textRef}>
                <h1
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-duration="2000"
                  data-aos-delay="800"
                  className="fw-semibold position-relative  mt-md-4 pt-5 ff_mont fs_100 text-white text-center"
                >
                  UNLOCK THE WORLD
                </h1>
                <p
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-duration="2000"
                  data-aos-delay="800"
                  className="fs_20 positin-relative z_index34  ff_opne text-center text-white"
                >
                  Explore . Connect . Share
                </p>
              </div>
            </div>
            <div className=" pt-md-5 mt-md-5 pt-lg-0 mt-lg-0 ppn">
              <div className=" position-relative z_index33 d-flex pt-5 mt-5 justify-content-center ">
                <img
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-duration="2000"
                  data-aos-delay="800"
                  className="w_100  d-md-block d-none "
                  src={group_image}
                  alt="group_image"
                />
                <img
                  className="w_100 d-block d-md-none img_Pos"
                  src={group_image1}
                  alt="group_image"
                />
                <div
                  className="position-absolute train_position d-md-block d-none"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-duration="2000"
                  data-aos-delay="800"
                >
                  <img
                    ref={trainImageRef}
                    className="your-image-class" // Add your class name here
                    src={yourTrainImageSource}
                    alt="Train Image"
                  />
                </div>
                <div className="position-absolute plane_position d-md-block d-none">
                  <img
                    ref={planeRef}
                    className="your-image-class" // Add your class name here
                    src={plane2}
                    alt="Train Image"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className=" position-absolute input_position mt-sm-5">
            <div className="heroLogo mx-auto z_index33 position-relative">
              <img src={logo} alt="logo" width={"100%"} height={"100%"} />
            </div>
            <div className="mt-md-5 pt-md-5 d-flex justify-content-center z_index5 position-relative">
              <div className="serachboxContainer" onClick={()=>{navigate('/footprint')}}>
                <input
                  type="text"
                  className="searchInput"
                  placeholder="Search People & Places"
                />
                <img src={srchbtn} alt="search" className="cursor-pointer" />
              </div>{" "}
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Hero;
