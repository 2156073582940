import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getStoriesDetailsbyId,
  get_haltDetails_by_id,
} from "../../Services/Stories ";
import { getUserDetails, getUserStats } from "../../Services/User";
import { getTrendingTrps } from "../../Services/HomeServices";
import { getVideo } from "../../Services/Video";

const initialState = {
  data: [],
  videos: [],
  trips: [],
  stats: [],
  ai: [],
  loading: false,
  error: null,
};

export const fetchuserDetails = createAsyncThunk(
  "/getUserDetails",
  async (id) => {
    try {
      const data = await getUserDetails(id);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUserTripsDetails = createAsyncThunk(
  "/getUserTripsdetails",
  async (id) => {
    try {
      const data = await getTrendingTrps(id);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUserstatsDetails = createAsyncThunk(
  "/getUserstatsdetails",
  async (id) => {
    try {
      const data = await getUserStats(id);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUserVideoDetails = createAsyncThunk(
  "/getUserVideosdetails",
  async (id) => {
    try {
      const data = await getVideo(id);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const UserDetailsSlice = createSlice({
  name: "Trending",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchuserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchuserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchuserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(fetchUserTripsDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserTripsDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.trips = action.payload;
      })
      .addCase(fetchUserTripsDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

      
  },
});

export default UserDetailsSlice.reducer;
