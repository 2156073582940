import React from "react";
import { useParams } from "react-router-dom";
import { getUserDetails } from "../../Services/User";
import { useState } from "react";
import { useEffect } from "react";
import { getImageURL } from "../../Services/Common/helper";
import you_icon from "../../Assets/png/you_icon.png";
import insta_icon from "../../Assets/png/insta_icon.png";
import x_icon from "../../Assets/png/x_icon.png";

const TavellDetailHero = () => {
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState("");

  const getUserDetail = async () => {
    const res = await getUserDetails(id);
    setUserDetails(res);
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <div className="bg-white">
      <div className=" custom_container py-5">
        <div className="bg-black rounded-4 py-5">
          <div className="d-sm-flex justify-content-between w-100 align-items-end gap-3">
            <div className="d-flex ps-4 pb-4 align-items-end gap-3">
              <div className="userPic2 cursor-pointer">
                <img
                  src={getImageURL(userDetails?.image)}
                  className="cardimg"
                />
              </div>
              <div>
                <h5 className=" fs_24 fw-semibold ff_opne text-white mb-1">
                  {userDetails?.first_name + " " + userDetails?.last_name}
                </h5>
                <p className=" fs_16 fw-normal ff_opne text-white mb-0">
                  {userDetails?.state}, {userDetails?.country}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center gap-5 pe-4 pb-4 ps-4 ps-sm-0">
              <div>
                <h5 className=" fs_20 fw-semibold ff_opne text-white mb-1">
                  {userDetails?.followers_count}
                </h5>
                <p className="fs_16 fw-normal ff_opne text-white mb-0">
                  Followers
                </p>
              </div>
              <div>
                <h5 className="fs_20 fw-semibold ff_opne text-white mb-1">
                  {userDetails?.following_count}
                </h5>
                <p className="fs_16 fw-normal ff_opne text-white mb-0">
                  Following
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3 mt-4">
          <img
            height={60}
            className=" cursor-pointer icon_box"
            src={you_icon}
            alt=""
          />
          <img
            height={60}
            className=" cursor-pointer icon_box"
            src={insta_icon}
            alt=""
          />
          <img
            height={60}
            className=" cursor-pointer icon_box"
            src={x_icon}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default TavellDetailHero;
