import React from "react";
import Features from "./Features";
function OverVision() {
  return (
    <div>
      <div className="our_view ">
        <div className="custom_container">
          <h1 className="fs_48 fw-semibold ff_opne text-center"> Our Vision</h1>
          <p className="fs_24 color_gray3 ff_opne text-center">
            Our vision is to enable the world to experience the world. We are
            creating an ecosystem that unites travel inspiration with content
            creation and experience providers with adventure, cultural, and
            culinary travel seekers. We intend to connect travellers worldwide
            and be their companion on each step of their Journey.
          </p>
          <div className="grassimge "></div>
        </div>
      </div>
      <Features />
    </div>
  );
}

export default OverVision;
