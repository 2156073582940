import React, { useEffect } from "react";

function PolicyPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      scroll: "smooth",
    });
  }, []);
  return (
    <div className="container">
      <div className="section">
        <h2 className="heading text-white">Returns & Refunds:</h2>
        <p className="text text-white">
          We are committed to ensuring your satisfaction with our automated VFX
          videos. In the unlikely event that you are not completely satisfied
          with your purchase, we offer a straightforward returns and refunds
          policy. If you encounter any issues with your video, please contact us
          within 2 days of receiving it. We will work diligently to address your
          concerns and provide a resolution, which may include a replacement
          video or a full refund. Please note that refunds may take 5 working
          days to process, depending on your payment method and financial
          institution. For further assistance regarding returns or refunds,
          please reach out to our customer support team at{" "}
          <a href="mailto:Raghav@trotterit.com" className="emailLink">
            Raghav@trotterit.com
          </a>
          .
        </p>
      </div>

      <div className="section">
        <h2 className="heading text-white">Shipping and Delivery:</h2>
        <p className="text text-white">
          We strive to provide efficient and timely delivery of our automated
          VFX videos to our valued clients. Upon placing an order, please allow
          for processing time, which may vary depending on the complexity of the
          project; Between 24 hours to 72 hours. Once your video is ready, it
          will be securely packaged and delivered electronically to the email
          address provided during checkout. We aim to fulfill orders promptly,
          typically within between 24 hours to 72 hours from the date of
          purchase. However, please note that delivery times may vary depending
          on factors such as order volume and customization requirements. For
          any inquiries regarding shipping or delivery, feel free to contact our
          support team at{" "}
          <a href="mailto:Raghav@Trotterit.com" className="emailLink">
            Raghav@Trotterit.com
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default PolicyPage;
