import React, { useEffect, useState } from 'react'
import BlogDetail from "../Assets/png/Blogdeails.png"
import { Outlet, useParams } from 'react-router-dom'
import BlogReducer, { fetchblogDetails } from '../Redux/Blog/BlogReducer'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { Parser } from "html-to-react";

function BlogDetails() {
  const { title, id } = useParams()
  const dispatch = useDispatch(BlogReducer)
  const blogDetail = useSelector((state) => state.Blog.blogDetails)
  const [blogHalts, setBlogHalts] = useState([])
  useEffect(() => {
    setBlogHalts(blogDetail?.halts)
  }, [blogDetail])
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    dispatch(fetchblogDetails(id))
  }, [])
  const htmlParser = new Parser();
  function getMonthYearFromDate(dateString) {
    const date = new Date(dateString);

    const months = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];

    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${year}`;
  }
  return (
    <div>
      <div className='w-100 position-relative blog-hero-container d-flex justify-content-center align-items-center'>
        <img src={"https://storage.trotterit.com/images/blog/" + blogDetail?.blog?.image || BlogDetail} className='w-100 max-h-80 object-fit-cover' />
        <div className='position-absolute top-0 d-flex h-100 text-white align-items-center'>
          <div className='text-center'>
            <h1 className='fw-oppa fs_100  text-shadow w-75 mx-auto'>{title}</h1>
          </div>
        </div>
      </div>
      <div className={"min-vh-100 bg-white"}>
        <div className='container'>
          <div className='pt-4'>
            <span className='fs_24 '>TRAVEL APPS  |  {getMonthYearFromDate(blogDetail?.blog?.updatedAt)}</span>
          </div>
          <div>
            <p className='fs_48 fw-bold'>{title}</p>
          </div>
          <div>
            <span>
              BY TROTTER IT
            </span>
          </div>
        </div>
        <div className=' container mt-4 pt-4'>
          <Row>
            <Col lg={3}>
              <div className='card p-4 '>
                {blogHalts?.map((item, index) => {
                  return (
                    <div className='dotted' key={index}>
                      <ul className='cursor-pointer'>
                        <li>
                          <a href={"#" + item?.title}>
                            {item?.title}
                          </a>
                        </li>
                      </ul>
                    </div>
                  )
                })}
              </div>
            </Col>
            <Col lg={9}>
              {blogHalts?.map((item, index) => {
                return (
                  <div key={index} id={item?.title} className='my-4 pb-4'>
                    <div className=''>
                      <h1 className='ps-3 pb-3'>
                        {item?.title}
                      </h1>
                      <img src={"https://storage.trotterit.com/images/blog/" + item.media[0].media} className='w-100 rounded-lg' />
                      <div className='ps-3 mt-3'>
                        <p>
                          {htmlParser.parse(item?.description)}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default BlogDetails