import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { deleteUser, sendOtp, verifyOtp } from '../../Services/User'

function DeleteProfile({ open, close, }) {
    const [step, setStep] = useState(0)
    const [email, setEmail] = useState(" ");
    const [uId, setUId] = useState(null);
    const [otpSent, setOtpSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const [otpDigits, setOtpDigits] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef(otpDigits.map(() => React.createRef()));
    const [message, setMessage] = useState(true)
    const [Username, setUsername] = useState("")
    const handleChange = (index, value) => {
        if (/^\d*$/.test(value)) {
            const newOtpDigits = [...otpDigits];
            newOtpDigits[index] = value;
            setOtpDigits(newOtpDigits);

            // Move focus to the next input field
            if (index < otpDigits.length - 1 && value !== '') {
                inputRefs.current[index + 1].current.focus();
            }
        }
    };

    const handleSubmit2 = async (e) => {
        e.preventDefault();
        if (otpDigits.every((digit) => digit !== '')) {
            let OTP = otpDigits.join('')
            await verifyOtp(uId, OTP).then(async (res) => {
                if (res) {
                    const resp2 = await deleteUser(uId)
                    if (resp2) {
                        setMessage(false)
                        setEmail(" ")
                        setOtpDigits(['', '', '', '', '', ''])
                    }

                } else {
                    alert("Incorrect Otp")
                }
            })

        } else {
            // Handle case where not all inputs are filled
            alert('Please fill in all the OTP digits.');
        }
    };

    useEffect(() => {
        if (email == "") {
            setErrorMessage("Please Enter Email First")
        } else {
            setErrorMessage("")
        }
    }, [email])
    async function handleSubmit(e) {
        e.preventDefault()
        if (email == "" || email == " ") {
            setErrorMessage("Please Enter Email First")
        } else {
            const res = await sendOtp(email)
            if (res?.status === "success") {
                setOtpSent(true)
                setUId(res?.data?.userDetails?.id)
                setUsername(res?.data?.userDetails?.first_name + " " + res?.data?.userDetails?.last_name)
            } else {
                alert(res?.message)
            }
        }
    }
    return (
        <div>
            <Modal show={open} onHide={() => { close(); setOtpSent(false); setEmail(""); setOtpDigits(['', '', '', '', '', '']) }} size='md' >
                <Modal.Header closeButton className='border-bottom-0'>
                    Delete Account
                </Modal.Header>
                <Modal.Body>
                    {
                        message ? <>{!otpSent ? <Form onSubmit={handleSubmit}>
                            <p>
                                If you delete your account, you will lose all your  data. Are you sure you want to delete your account?
                            </p>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" value={email} placeholder="name@example.com" onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>
                            <div className='mb-2 text-danger'>
                                {errorMessage}
                            </div>
                            <Button className='btn signup fs_16 fw-semibold ff_opne' type='submit'>Send Otp</Button>
                        </Form> : <Form onSubmit={handleSubmit2}>
                            <Form.Group controlId="formOtp" className='d-flex gap-2 justify-content-center mb-3'>
                                {otpDigits.map((digit, index) => (
                                    <Form.Control
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        value={digit}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        ref={inputRefs.current[index]}
                                        style={{ width: '2rem', marginRight: '0.5rem' }}
                                        required
                                    />
                                ))}
                            </Form.Group>

                            <div className='d-flex justify-content-center'>
                                <Button className='btn signup fs_16 fw-semibold ff_opne' type="submit">
                                    Submit OTP
                                </Button>
                            </div>
                        </Form>}</> : <div>
                            <p>
                                Dear {Username},
                                <br></br>

                                <br></br>
                                This is to inform you that your account on trroterit will be deleted in 14 days from today. We appreciate your past engagement and want to ensure you are aware of this upcoming change.
                            </p>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default DeleteProfile