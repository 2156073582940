import { useState } from "react";
import { useEffect } from "react";
import map_image from "../Assets/png/map.png";
import travele_image from "../Assets/png/travel.png";
import loc from "../Assets/png/loc_icon.png";
import { getTavell } from "../Services/Tavell";
import { getImageURL } from "../Services/Common/helper";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const Travelers = () => {
  const [tavell, setTavell] = useState([]);
  const getTavellpage = async () => {
    const res = await getTavell();
    setTavell(res);
  };

  useEffect(() => {
    getTavellpage();
  }, []);

  var settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="bg-whit bg_grey bg_image py-5 travellers-sec">
      <div className="custom_container">
        <div>
          <h5 className="mb-2 text-center fs_48 ff_opne color_gray1">
            Inspiring Travelers
          </h5>
          <p className="mb-0 text-center fs_22 ff_opne color_gray3">
            Meet Extraordinary Travelers Whose Remarkable Journeys, Stories, and
            Insights<br></br> Ignite the Flames of Wanderlust in Every
            Adventurer's Heart
          </p>
        </div>
        <div className="position-relative pt-3">
          <div className="">
            <Slider {...settings}>
              {tavell.map((obj, i) => (
                <div
                  key={i}
                  // data-aos="fade-up"
                  // data-aos-anchor-placement="top-bottom"
                  // data-aos-duration="2000"
                  // data-aos-delay="1000"
                  // data-aos-offset="300"
                  className=""
                >
                  <div className="position-relative mt-4">
                    <Link to="/tavell">
                      <div
                        style={{
                          width: "200px",
                        }}
                        className="mx-auto travele_box cursor-pointer h-100 text-center"
                      >
                        <img
                          className="object-fit-cover coustom_heigt w-100 mx-auto"
                          src={getImageURL(obj.image)}
                          alt="travele_image"
                        />
                        <h5 className="mb-0 mt-1 color_gray1">
                          {obj.first_name}
                        </h5>
                        <div className="d-flex justify-content-center gap-2 align-items-center mt-2">
                          <img width={15} src={loc} alt="loc" />
                          <p className="mb-0 color_gray1">{obj.country}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-3">
                          <div>
                            <p className="mb-0 fw-semibold ff_opne color_gray1">
                              {obj.following_count}
                            </p>
                            <p className="mb-0 ff_opne color_gray1">
                              Following
                            </p>
                          </div>
                          <div>
                            <p className="mb-0 fw-semibold ff_opne color_gray1">
                              {obj.followers_count}
                            </p>
                            <p className="mb-0 ff_opne color_gray1">
                              Followers
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Travelers;
