import React, { useEffect } from 'react'

function Adveritsment() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            scroll: "smooth"
        })
    }, [])
    return (
        <div className='min-vh-100 bg-white'>
            <div className="hero">
                <div className="black-shade"></div>
                <div className="z-1 d-flex flex-column justify-content-center text-center gap-4">
                    <h1 className="text-white">Get Started with Trotter It Ads</h1>
                    <button className="mx-auto rounded-1 px-4 py-2 border-0 blue_bg text-white btn-sm">
                        <i className="fa-brands fa-whatsapp"></i> DISCUSS YOU AD CAMPAIGN
                    </button>
                </div>
            </div>

            <div className="my-5 d-none d-md-flex py-3 px-2  justify-content-center">
                <iframe width="1000" height="562" src="https://www.youtube.com/embed/ECxm10o60fU"
                    title="Trotter It - Travel Journal &amp; Social Network App" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
            </div>

            <div className="my-5 d-md-none d-flex py-3 px-2  justify-content-center">
                <iframe width="500" height="162" src="https://www.youtube.com/embed/ECxm10o60fU"
                    title="Trotter It - Travel Journal &amp; Social Network App" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
            </div>

            <div className="container mx-auto py-5">
                <div>
                    <h2 className="text-center">
                        Reach your ideal customer on the most dedicated platform for
                        travellers
                    </h2>
                </div>
                <div className="d-flex justify-content-between my-5">
                    <div className="d-flex flex-column gap-1">
                        <p className='h4'>4 of 5</p>
                        <p className="text_grey">Users are ideating on their next travel adventure</p>
                    </div>
                    <div className=" d-flex flex-column gap-1">
                        <p className='h4'>2x</p>
                        <p className="text_grey">The average spending power of users on the app
                        </p>
                    </div>
                    <div className=" d-flex flex-column gap-1">
                        <p className='h4'>#1</p>
                        <p className="text_grey">First of its kind Indian App for travellers
                        </p>
                    </div>
                </div>
            </div>

            <div className="container mx-auto py-5">
                <div>
                    <h2 className="text-center">
                        All that you need to get started
                    </h2>
                </div>
                <div className="d-flex justify-content-between my-5">
                    <div className=" d-flex flex-column gap-3">
                        <div className="d-flex gap-4 align-items-center"><i className="fa-regular fa-image blue_text fs-3"></i> IMAGE
                        </div>
                        <p className="text_grey">Image for your advertisement
                        </p>
                    </div>
                    <div className=" d-flex flex-column gap-3">
                        <div className="d-flex gap-4 align-items-center"><i className="fa-solid fa-h blue_text fs-3"></i> HEADING </div>
                        <p className="text_grey">Headline for your ad copy
                        </p>
                    </div>
                    <div className=" d-flex flex-column gap-3">
                        <div className="d-flex gap-4 align-items-center"><i
                            className="fa-solid fa-arrow-up-right-from-square blue_text fs-3"></i> WEBSITE</div>
                        <p className="text_grey">Your Website URL
                        </p>
                    </div>
                </div>
            </div>
            <div className='bg-dark min-h-60 d-flex align-items-center justify-content-center'>
                <div className='text-center  text-white'>
                    <h4 className='my-4 fs_32'>Start your ad campaign in minutes.</h4>
                    <p className='my-4 fs_24'>Connect with us</p>
                    <div className='d-flex justify-content-center'>
                        <button className='btn btn-light rounded-pill text-capitalize fw-bold d-flex align-items-center gap-3'>
                            <i className="fa-brands fa-whatsapp" />
                            Discuss Now
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Adveritsment