import React, { useEffect } from "react";
import BlogHeroImage from "../Assets/png/Bloghero.png";
import blog from "../Assets/png/blog.png";
import { Card, Col, Row } from "react-bootstrap";
import blog2 from "../Assets/png/blog-2.png";
import icon from "../Assets/png/ti_icon.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BlogReducer, { fetchAllBogs } from "../Redux/Blog/BlogReducer";

function TravelBlogs() {
  const navigate = useNavigate();
  const dispatch = useDispatch(BlogReducer);
  const AllBlog = useSelector((state) => state.Blog.blogs);

  function showFirst200Words(text) {
    // Split the text into words
    var cleanText = text.replace(/<\/?[^>]+(>|$)/g, "");

    // Split the text into words
    var words = cleanText.split(" ");

    // Take the first 200 words
    var first200Words = words.slice(0, 50).join(" ");

    // Add "..." at the end
    var result = first200Words + "...";

    return result;
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    dispatch(fetchAllBogs());
  }, []);

  return (
    <div className="">
      <div className="w-100 position-relative blog-hero-container d-flex justify-content-center align-items-center">
        <img
          src={BlogHeroImage}
          className="w-100 min-vh-100 md: max-h-80 object-fit-cover"
        />
        <div className="position-absolute top-0 d-flex min-vh-100 text-white align-items-center">
          <div className="text-center">
            <h1 className="fw-oppa fs_100  text-shadow">INTO THE BLUE</h1>
            <span className="fw-oppa fs_20 text-shadow">
              Explore . Connect . Share
            </span>
          </div>
        </div>
      </div>
      <div className="w-100  travel_app_container">
        <div

          className="costom_width_90 mx-auto"
        >
          <div className="d-flex align-items-center justify-content-center w-100 position-relative cursor-pointer" onClick={() => {
            navigate("/Blog/" + AllBlog[0]?._id + "/" + AllBlog[0]?.title);
          }}>
            <img
              className="w-100 object-fit-cover travel-blog-image rounded-lg"
              src={
                "https://storage.trotterit.com/images/blog/" + AllBlog[0]?.image
              }
              alt=""
            />
            <div className="bg-white p-1 position-absolute travel-apps-box">
              <div className="travel-apps-inside-box p-1 d-flex flex-column align-items-center justify-content-center text-center">
                <p className="p-0 color_dark_orange">TRAVEL APPS</p>
                <p className="p-0 w-75 ff_outfit fs_32">{AllBlog[0]?.title}</p>
                <p className="p-0">By Trottrt It</p>
              </div>
            </div>
          </div>
          <Row className="w-100 ps-2 my-4 pb-4">
            {AllBlog?.slice(0, 4).map((item, index) => {
              return (
                <Col
                  sm={3}
                  lg={3}
                  key={index}
                  className="scale2 cursor-pointer"
                  onClick={() => {
                    navigate("/Blog/" + item?._id + "/" + item?.title);
                  }}
                >
                  <img
                    src={
                      "https://storage.trotterit.com/images/blog/" + item?.image
                    }
                    className="w-100 rounded-lg"
                    height={"220px"}
                  />
                  <p className="text-white pt-2">{item?.title}</p>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <div className="min-vh-100 bg-white pb-4">
        <div className="costom_width_90 mx-auto">
          <img src={blog2} className="w-100" />
        </div>
        <div className="costom_width_90 mx-auto">
          <Row className="">
            {AllBlog.map((item, index) => {
              return (
                <Col lg={6} className="my-3">
                  <Card
                    className="p-3 rounded-lg w-100 scale2 cursor-pointer shadow-lg h-100"
                    onClick={() => {
                      navigate("/Blog/" + item?._id + "/" + item?.title);
                    }}
                  >
                    <Card.Img
                      variant="top"
                      src={
                        "https://storage.trotterit.com/images/blog/" +
                        item?.image
                      }
                      height={"274px"}
                      className="rounded-lg"
                    />
                    <Card.Body className="px-0">
                      <Card.Title>{item?.title}</Card.Title>
                      <Card.Text>
                        {showFirst200Words(item?.description)}
                      </Card.Text>
                    </Card.Body>
                    <Card.Body>
                      <img src={icon} />
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default TravelBlogs;
