import { axiosInstance } from "./Common/AxiosInstanc";
import { decryptResponse } from "./Common/helper";

export const getTrendingTrpis = async (index) => {
  const res = await axiosInstance({
    url: "wall?index=" + index,
    method: "GET",
  });
  if (res.status === 200) {
    const key = localStorage.getItem("aax");
    const re = decryptResponse(key, res?.data?.data);
    return re;
  }
};

export const getTrendingTrps = async (userId) => {
  const res = await axiosInstance({
    url: "get_user_trip/" + userId,
    method: "GET",
  });
  if (res.status === 200) {
    const key = localStorage.getItem("aax");
    const re = decryptResponse(key, res?.data?.data);
    return re;
  }
};
