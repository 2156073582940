import React from "react";
import TavellDetailHero from "../Components/TavellDetails/TavellDetailHero";
import TavelDetailTab from "../Components/TavellDetails/TavelDetailTab";

const TavellDetailPage = () => {
  return (
    <div>
      <TavellDetailHero />
      <TavelDetailTab />
    </div>
  );
};

export default TavellDetailPage;
