import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getStoriesDetailsbyId,
  get_haltDetails_by_id,
} from "../../Services/Stories ";

const initialState = {
  data: [],
  halt: [],
  loading: false,
  error: null,
};

export const fetchStoryDetails = createAsyncThunk(
  "/getStoryDetails",
  async (id) => {
    try {
      const data = await getStoriesDetailsbyId(id);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchStoryHaltDetails = createAsyncThunk(
  "/getStoryHaltdetails",
  async (id) => {
    try {
      const data = await get_haltDetails_by_id(id);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const StoryDetailsSlice = createSlice({
  name: "Trending",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoryDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStoryDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchStoryDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(fetchStoryHaltDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStoryHaltDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.halt = action.payload;
      })
      .addCase(fetchStoryHaltDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default StoryDetailsSlice.reducer;
