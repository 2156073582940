import axios from "axios";

export const getToken = async () => {
  const response = await axios({
    url: "https://apis.trotterit.com/v2/web/token_generate/5",
    method: "GET",
  });
  localStorage.setItem("aax", response.data.data.web_token);
  localStorage.setItem("auth-token", response.data.token);
  return response.data.token;
};
