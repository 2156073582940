import React, { useDebugValue, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Slider from "react-slick";
import asia from "../../Assets/img/Rectangle 750.jpg";
import europe from "../../Assets/img/Rectangle 751.jpg";
import africa from "../../Assets/img/Rectangle 752.jpg";
import america from "../../Assets/img/Rectangle 748.jpg";
import closebtn from "../../Assets/png/closebtn.png";
import { getCounrty } from "../../Services/CountryService";
import { getImageURL, removeUnwanted } from "../../Services/Common/helper";
import eye from "../../Assets/png/Vector 8.png";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CountryTripsModal({ show, close, country }) {
  const [currentImage, setCurrentImage] = useState();
  const [activeSlide, setActiveSlide] = useState(0);
  const [Slide, setSlide] = useState(5);
  const [trips, setTrips] = useState([]);
  const getCountryTrips = async (c) => {
    const res = await getCounrty(c);
    setTrips(res);
  };

  const settings = {
    className: "center",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Slide,
    centerMode: true,
    autoplay: false,
    centerPadding: "0px",
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Slide,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (country) {
      getCountryTrips(country);
    }
    switch (country) {
      case "asia":
        setCurrentImage(asia);
        break;
      case "europe":
        setCurrentImage(europe);

        break;
      case "africa":
        setCurrentImage(africa);

        break;
      case "america":
        setCurrentImage(america);

        break;
      default:
        break;
    }
  }, [country]);

  useEffect(() => {
    switch (trips.length) {
      case 1:
        setSlide(1);
        break;
      case 2:
        setSlide(1);
        break;
      case 3:
        setSlide(2);
        break;
      case 4:
        setSlide(3);
        break;
      default:
        setSlide(5);
        break;
    }
  }, [trips]);

  return (
    <div>
      <Modal
        show={show}
        onHide={close}
        dialogClassName="modal-90w rounded-lg p-0"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div>
            <div>
              <div className="CountryModalImg position-relative">
                <img src={currentImage} alt="" />
                <div className="position-absolute closebtn" onClick={close}>
                  <img src={closebtn} />
                </div>
                <div
                  className="position-absolute bottom-0 left-3"
                  onClick={close}
                >
                  <span className="text-white ff_opne text-size-md text-uppercase">
                    {country}
                  </span>
                </div>
              </div>
              <div>
                {trips?.length > 0 ? (
                  <>
                    <div className="d-flex justify-content-center mt-4">
                      <Slider
                        {...settings}
                        className="d-flex justify-content-center"
                      >
                        {trips?.map((item, index) => {

                          return (
                            <>
                              <div className="mt-4 p-4 cursor-pointer">
                                <div className="position-relative">
                                  <ReactPlayer
                                    url={
                                      "https://storage.trotterit.com/videos/" +
                                      item?.video_info?.media
                                    }
                                    pip={true}
                                    controls={false}
                                    playing={
                                      index === activeSlide ? true : false
                                    }
                                    loop
                                    muted={index === activeSlide ? false : true}
                                    playsinline={true}
                                    className={ index === activeSlide ? "video2  video3" :"video2"}
                                  />
                                  {activeSlide === index && (
                                    <div className="position-absolute justify-content-between w-100 d-flex align-items-end gap-2 videoloction_position">
                                      <div>
                                        <div className="d-flex align-items-center gap-2">
                                          <div className="descrip-img">
                                            <img
                                              src={
                                                item.user_info.image !==
                                                undefined
                                                  ? getImageURL(
                                                      item.user_info.image
                                                    )
                                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQdKMdBvGVzyIwLlMiAP4KYBiWGpUPpedqcw&usqp=CAU"
                                              }
                                              className="cardimg"
                                            />
                                          </div>
                                          <div>
                                            <span className="text-white">
                                              {item.user_info.first_name}
                                            </span>
                                            <span className="text-white">
                                              {item.user_info.state +
                                                ", " +
                                                item.user_info.country}
                                            </span>
                                          </div>
                                        </div>
                                        <p className="mb-0  fw-semibold text-white ff_opne color_gray3">
                                          {item?.video_info?.title}
                                        </p>
                                      </div>

                                      <div className=" pe-5">
                                        <p className="mb-0 ps-2 ms-1 fs_16 fw-semibold text-white ff_opne color_gray3">
                                          {item?.video_info?.view_count}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </Slider>
                    </div>
                  </>
                ) : (
                  "no Trips"
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CountryTripsModal;
