import axios from "axios";
import { getToken } from "../AuthApi";

export const getAllBlogs = async (index) => {
    const res = await axios({
        url: `https://storage.trotterit.com/v2/all_blogs`,
        method: "get",
        headers: {
            "x-access-token": await getToken(),
        },
    });

    if (res.status === 200) {
        return res?.data?.data
    } else {
        return []
    }
}

export const singleBlogDetail = async (id) => {
    const res = await axios({
        url: `https://storage.trotterit.com/v2/blog_details/` + id,
        method: "get",
        headers: {
            "x-access-token": await getToken(),
        },
    });
    if (res.status === 200) {
        return res?.data?.data?.data
    } else {
        return []
    }
}