import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAiTemplate } from "../../Services/AITemplate";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const getAiVideo = createAsyncThunk("/getAivideo", async () => {
  try {
    const data = await getAiTemplate();
    return data;
  } catch (error) {
    throw error;
  }
});

const AiVideoSlice = createSlice({
  name: "Trending",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAiVideo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAiVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAiVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default AiVideoSlice.reducer;
