import React, { useEffect } from "react";

function Policy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      scroll: "smooth",
    });
  }, []);
  return (
    <div className="bg-texture bg-white min-vh-100">
      <div className="container py-4 ">
        <h1 className="display-5 py-4 my-4">Privacy Policy</h1>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>1. Introduction</strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          The website www.TrotterIt.com and the associated mobile application
          (Android) (hereinafter referred to as the &ldquo;
          <strong style={{ color: "rgb(48, 48, 48)" }}>Website</strong>&rdquo;)
          is owned by &nbsp;Techedge Systems &amp; Engineers Private Limited, a
          company incorporated under the Companies Act, 2013 with its registered
          office at Sarita Vihar, Delhi (&ldquo;
          <strong style={{ color: "rgb(48, 48, 48)" }}>Trotter It</strong>
          &ldquo;). Trotter It offers the following services: Providing a
          website, and apps for the Android, providing a platform for the
          creation of travel blogs, archiving personal travel stories, photos
          and video (hereinafter &ldquo;Memories&rdquo;) and for use as a social
          network for travelers. Trotter It also offers the following products:
          Personalized travel videos, expanding the range reserved, which are
          configured and manufactured by Trotter It on the basis of data
          provided by the user (hereinafter &ldquo;products&rdquo;) (products
          and services are jointly referred to as &ldquo;services&rdquo;).
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>2. Scope</strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          This privacy policy (&ldquo;Policy&rdquo;) is an electronic record in
          the form of an electronic contract formed under the information
          technology act, 2000 and the rules made thereunder and the amended
          provisions pertaining to electronic documents /records in various
          statutes as amended by the information technology act, 2000. This
          policy does not require any physical, electronic or digital signature.
          This policy is a legally binding document between you and trotter it.
          The terms of this privacy policy will be effective upon your
          acceptance of the same (directly or indirectly in electronic form, by
          clicking on the &ldquo;I accept&rdquo; tab or by use of the website or
          by other means and will govern the relationship between you and
          Trotter It for your use of the website (defined below). This document
          is published and shall be construed in accordance with the provisions
          of the information technology (reasonable security practices and
          procedures and sensitive personal data of information) rules, 2011
          under information technology act, 2000; that require publishing of the
          privacy policy for collection, use, storage and transfer of sensitive
          personal data or information. Please read this privacy policy
          carefully. By using the website, you indicate that you understand,
          agree and consent to this privacy policy. If you do not agree with the
          terms of this privacy policy, please do not use this website. You
          hereby provide your unconditional consent or agreement to Trotter It
          as provided under section 43a and section 72a of information
          technology act, 2000.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>3. Consent</strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          By providing us Your information or by making use of the Website, You
          hereby consent to the collection, storage, processing and transfer of
          any or all of Your Personal Information and Non-Personal Information
          by Trotter It as specified under this Policy. You further agree that
          such collection, use, storage and transfer of Your Information shall
          not cause any loss or wrongful gain to You or any other person. The
          terms &ldquo;You&rdquo;/&rdquo;Your&rdquo;/&rdquo;Yourself&rdquo;
          refer to the users. This Policy is a contract between You and Trotter
          It and shall be deemed to be incorporated into the Terms of Use of the
          Website and shall be read in addition to the Terms of Use.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            4. What Information Is Collected
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          4.1. The Information Technology (Reasonable Security Practices and
          Procedures and Sensitive Personal Data or Information) Rules, 2011
          defines &ldquo;Personal Information&rdquo; as any information that
          relates to a natural person, which, either directly or indirectly, in
          combination with other information available or likely to be available
          with a body corporate, is capable of identifying such person.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          4.2. For the purpose of this Policy, the term &ldquo;
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            Personal Information
          </strong>
          &rdquo; shall mean any information you provide to Trotter It and shall
          include, without limitation name, email address, birthday, profession,
          country or city you live in along with your travel preferences.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          4.3. For the purpose of this Policy, the term &ldquo;
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            Non-Personal Information
          </strong>
          &rdquo; shall mean information that does not specifically identify an
          individual but includes information from you, such as the type of
          internet browser you use, mobile device you use, your computer or your
          mobile device&rsquo;s unique device ID, the URL of the previous
          website you visited, your computer&rsquo;s operating system, your
          Internet Service Provider (ISP) and your Internet Protocol (IP)
          address. Trotter It may also collect Non-Personal Information that you
          voluntarily provide, such as information included in response to a
          questionnaire or a survey conducted by Trotter It.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            5. Collection of Information
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          5.1. When you sign up for Trotter It, or when you use our products or
          services, or when you visit the Website, or when you install Trotter
          It Mobile Application, you are required to provide certain Personal
          Information. Trotter It may combine information about you that we have
          with information we obtain from business partners or other companies.
          When you register with us through Facebook or Google Plus we extract
          or ask for information such as your name, email address, birth date,
          gender, pin code, occupation, industry, and personal interests. Once
          you register with Trotter It and log in to its services, you are not
          anonymous to us. Trotter It is the sole owner or license holder of the
          information collected on this and other sites maintained and owned by
          it. Trotter It believes in keeping its users&rsquo; Personal
          Information private and confidential.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          5.2. We receive and store any information you enter on our Website or
          provide to us in any other way. The types of Personal Information
          collected may include your name, email address, birthday, Twitter,
          Google, and/or Facebook usernames, location, use information regarding
          your use of our Services, and browser information. Your information
          also includes the information you choose to share on Trotter It, such
          as when you start a journey, record past travels, upload a status,
          check-in at a location, etc. or when your friends upload information
          about you etc. It also includes the information you choose to share
          when you communicate with us, such as when you contact us using an
          email address, or when you take an action, such as when you add a
          friend, like a Page, add a place to your story etc. Your name, profile
          pictures and / or cover photos, travel map, journey and username
          (email address) are treated just like information you choose to make
          public. Your birthday allows us to do things like show you
          age-appropriate content and advertisements. We also automatically
          receive your location when you use the Services. We use various
          technologies to determine location, such as location services of the
          applicable operating system or browser and sensor data from your
          device that may, for example, provide information on nearby Wi-Fi-
          access points and cell towers.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          5.3. We also receive other types of information about you:
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          5.3.1. When you post things like photos or videos on Trotter It, we
          may receive additional related data (or metadata), such as the time,
          date, and place you took the photo or video.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          5.3.2. We receive data from or about the computer or mobile phone or
          to access Trotter It, including when multiple users log in from the
          same device. This may include network and communication information,
          such as your IP address or mobile phone number, and other information
          about things like your internet service, operating system, location,
          the type (including identifiers) of the device or browser you use, or
          the pages you visit.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          5.3.3. We receive data whenever you visit our website that uses
          Trotter It on third party sites with a Trotter It feature (such as a
          social plugin), sometimes through cookies. This may include the date
          and time you visit the site; the web address, or URL, you&rsquo;re on;
          technical information about the IP address, browser and the operating
          system you use; and, if you are logged in to Trotter It, your User ID
          and your entire profile at Trotter It.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          5.3.4. We occasionally get data from our affiliates or our advertising
          partners, customers and other third parties that help us (or them)
          deliver ads, understand online activity, and generally make Trotter It
          better. For example, an advertiser may tell us information about you
          (like how you responded to an ad on Trotter It or on another site) in
          order to measure the effectiveness of and improve the quality of ads.
          We also put together data from the information we already have about
          you, your friends, and others, so we can offer and suggest a variety
          of services and features. We may provide data to our advertising
          partners or customers, or have combined it with other people&rsquo;s
          data.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          5.4. If you decide you would like to sign-up with Trotter It services
          using the using the &ldquo;Connect to Facebook&rdquo; or
          &ldquo;Connect to Google&rdquo; option, then you are giving Trotter It
          access to Your &lsquo;basic account information&rsquo; (including your
          date of birth) and &lsquo;public information&rsquo; which is defined
          by Facebook or Google respectively. You hereby authorize Trotter It to
          access your data / information (including &lsquo; basic account
          information&rsquo; and &lsquo;public information&rsquo;) from
          www.facebook.com, even while you are not online on Trotter It; You
          authorize Trotter It to pull &lsquo;posts&rsquo; &amp; pictures from
          your Facebook or Google account; You authorize Trotter It to push
          &lsquo;posts&rsquo; &amp; pictures to your Facebook or Google account.
          Trotter It undertakes not to transmit and/or transfer your this
          &lsquo;data / information&rsquo; to any other third party, which is
          primarily pulled / pushed from / to your Facebook or Google account.
          You authorize Trotter It to access &lsquo;your information&rsquo; as
          available on Facebook or Google for the purposes of enhancing our user
          experience on our website. In the event of any conflict between the
          privacy policy of Trotter It and the privacy policy of Facebook or
          Google, the privacy policy of Trotter It shall prevail.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          5.5. Public information When we use the phrase &ldquo;public
          information&rdquo;, we mean the information you choose to make public,
          as well as information that is always publicly available. If you chose
          to make and keep your information public, your information then is
          always public. In that event, there is no privacy setting applicable
          for you to choose who may or may not see some or part of your
          information. &lsquo;Everyone&rsquo; will be able to see it. Your
          profile becomes publicly accessible on the internet, and potentially
          every individual in the world can see it. When you use the Services,
          certain information is public, such as your public user profile
          information, public trips (including their texts, photos and other
          information related to the trip), travel stats, badges, and lists of
          friends and/or followers (as applicable to a particular Service), and
          maybe searchable by search engines and be shared with or disclosed to
          third parties, to a wide range of users and services. We may also
          share or disclose aggregated and anonymous data, such as de-identified
          location data (that is, not tied to individual users), to third
          parties, including users you may follow, for analytical or other
          purposes. Certain Personal Information may be made available to third
          party developers if you or your &ldquo;friends&rdquo; use third-party
          apps, for example by sharing your trips to Facebook, Google or
          Twitter. You should review the policies of third-party apps and
          websites to ensure that you are comfortable with the ways in which
          they use and disclose the information you share with them. We do not
          guarantee that they will follow our rules or our Policy. Note that
          your friends will also be able to see which third-party apps you use.
          Choosing to make your information public also means that this
          information:
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          5.5.1. can be associated with you (i.e., your name, profile pictures,
          email ID, age, travel preferences, profession, journey and travel map,
          etc.) even off Trotter It;
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          5.5.2. can show up when someone does a search on Trotter It or on a
          public search engine;
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          5.5.3. will be accessible to the Trotter It-integrated applications,
          and websites you and your friends use; and
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          5.5.4. will be accessible to anyone who uses our API&rsquo;s.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          5.6. Your Username is your email address. Alternatively, you will be
          able to login with your Facebook, Twitter or Google plus user id and
          password. Once done, you will be bound and governed by the terms of
          use, legal, privacy policy and all other rules and regulations of
          Facebook, Twitter or Google respectively from time to time, in
          addition to the present policies and rules.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            6. Use of Information
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          We use the information we receive about you in connection with the
          services and features we provide to you and other users like your
          friends, our partners, the advertisers that purchase ads on the site
          and websites you use. For example, in addition to helping people see
          and find things that you do and share, we may use the information we
          receive about you:
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          6.1. To protect the rights, property, or personal safety of Trotter
          It, its users and the public as a part of our efforts to keep Trotter
          It products, services and integrations safe and secure;
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          6.2. To respond to claims that any content violates the rights of
          third-parties to protect Trotter It&rsquo;s or others&rsquo; rights or
          property;
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          6.3. To provide you with location features and services, like telling
          you and your friends when something is going on nearby;
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          6.4. To measure or understand the effectiveness of ads you and others
          see, including to deliver relevant ads to you;
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          6.5. To make suggestions to you and other users on Trotter It, such
          as: suggesting that your friend to use our travel agent (save and
          except that Trotter It takes no liability or responsibility for any
          performance or act of such other third party of travel agent) because
          you found friends using it, suggesting that another user add you as a
          friend because the user imported the same email address as you did, or
          suggesting that your friend tag you in a picture or an itinerary they
          have uploaded with you in it; and
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          6.6. For internal operations, including troubleshooting, data
          analysis, testing, research and service improvement, to comply with
          legal processes and to enforce the Terms of Services
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          6.7. Granting us permission to use your information not only allows us
          to provide Trotter It as it exists today, but it also allows us to
          provide you with innovative features and services we develop in the
          future that use the information we receive about you in new ways.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          6.8. While you are allowing us to use the information we receive about
          you, you always own all of your information.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          Your trust is important to us, which is why we don&rsquo;t share
          information we receive about you with others unless we have:
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          6.9. given you notice, such as by telling you about it in this Policy;
          or
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          6.10. removed your name and any other personally identifying
          information from it.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          6.11. For information others share about you, they control how it is
          shared and we may use it like any other information without
          distinguishing it.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          6.12. We store data for as long as it is necessary to provide products
          and services to you and others, including those described above.
          Typically, information associated with your account will be kept until
          your account is deleted. For certain categories of data, we may also
          tell you about specific data retention practices. We may enable access
          to public information that has been shared through our services. We
          may allow service providers to access information so they can help us
          provide services.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          If you would like to access, correct, amend or delete any Personal
          Information we have about you, you are invited to contact us by
          sending us mail at info@TrotterIt.com
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            7. Sharing of Information
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          In &ldquo;Settings&rdquo; you can choose who can see your profile
          based on &nbsp;privacy levels:
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          7.1. Only my followers: Only people that follow you can see your
          profile and your Travel Profile, Local Life and Profile including your
          Photos, Videos, Albums, Itineraries, Journeys, etc. Note that if you
          did not set your account type to &ldquo;private&rdquo;, anyone can
          follow you, which also means that anyone can potentially see your live
          journey updates. Everyone else who is not your Follower on Trotter It
          gets to view only your Profile information and Travel Map.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          7.2. Everyone: Your profile and activities becomes publicly accessible
          on the Internet, and potentially every individual in the world can see
          it.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          7.3. Staff access: Even if your privacy level is set to &ldquo;Only my
          followers&rdquo;, on an occasional basis Trotter It staff members may
          still access your data for research and testing purposes and to solve
          problems you might experience.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          7.4. Journey: The Live Journey &amp; Past Journey functionality may
          automatically make your current location publicly available if you
          have set the privacy level to &ldquo;Everyone&rdquo;. We urge you to
          think carefully about using the Live Journey &amp; Past Journey
          functionality and to keep into account possible consequences of
          publicly sharing your current location. People who can see your
          profile including Live Journeys, Past Journeys and Check-ins, can see
          all its details including your current location, previous locations,
          name, email, profile photo, hometown, badges, a list of your friends,
          steps you write or comment on, photos and videos you post, stories you
          write, and which Services you use. Individuals reading this
          information may use it or disclose it to other individuals or entities
          without our control and without your knowledge. We therefore urge you
          to think carefully about including any specific information you may
          deem private in trips, comments, plans or tips or other content
          (location or otherwise) that you create in the Services.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          These websites may collect data about you, use cookies, embed
          additional third-party tracking, and monitor your interaction with
          that embedded content, including tracking your interaction with the
          embedded content if you have an account and are logged in to that
          website.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            8. Account deletion
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          If you want to stop using your account, you can delete it at any time.
          When you delete your account, it is permanently deleted from Trotter
          It. It typically takes about one month to delete an account, but some
          information may remain in backup copies and logs for up to 180 days.
          You should only delete your account if you are sure you never want to
          reactivate it again. Certain information is needed to provide you with
          services, so we only delete this information after you delete your
          account. Some of the things you do on Trotter It aren&rsquo;t stored
          in your account, like posting to a group or sending someone a message
          (where your friend may still have a message you sent, even after you
          delete your account). That information remains after you delete your
          account. Following termination or deletion of your account, or if you
          remove all user content from Trotter It, we may retain some of that
          content for a commercially reasonable period of time for backup,
          archival, or audit purposes. Furthermore, Trotter It and its users may
          retain and continue to use, store, display, reproduce, re-pin, modify,
          create derivative works, perform, and distribute any of your content
          that other users have stored or shared through Trotter It. In order to
          delete your account, please send us a request on info@TrotterIt.com
          and we shall revert back to your at the earliest possible time and
          date.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            9. Sharing and finding you on Trotter It
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          You understand that all information, data, text, software, music,
          sound, photographs, graphics, video, messages or other materials
          (&ldquo;<strong style={{ color: "rgb(48, 48, 48)" }}>Content</strong>
          &ldquo;), whether publicly posted or privately transmitted, are the
          sole responsibility of the person from which such Content originated.
          This means that you, and not Trotter It, are entirely responsible for
          all Content that you upload, post, email, transmit or otherwise make
          available via the website. Trotter It does not control the Content
          posted via the website and, as such, does not guarantee the accuracy,
          integrity or quality of such Content. You understand that by using the
          website, you may be exposed to Content that is offensive, indecent or
          objectionable. Under no circumstances will Trotter It be liable in any
          way for any Content, including, but not limited to, for any errors or
          omissions in any Content, or for any loss or damage of any kind
          incurred as a result of the use of any Content posted, emailed,
          transmitted or otherwise made available via the website.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            10. Finding you on Trotter It (Search Bar)
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          Any person, whether a user or not, can look for you through the
          Trotter It search bar using the alphabets of your first name and last
          name.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            11. Access on phones and other devices
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          Once you share information with your friends and others, they may be
          able to sync it with or access it via their mobile phones and other
          devices. For example, if you share a photo on Trotter It, someone
          viewing that photo could save it using Trotter It tools or by other
          methods offered by their device or browser. Similarly, if you share
          your contact information via messaging / post a comment etc., with
          someone, they may be able to use Trotter It or devices to sync that
          information, and in all circumstances, you alone shall be responsible
          for such sharing of information. Or, if one of your friends has a
          Trotter It application on one of their devices, your information (such
          as the things you post or photos you share) may be stored on or
          accessed by their device. You should only share information with
          people you trust because they will be able to save it or re-share it
          with others, including when they sync the information to a device.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            12. Activity Feed, Journeys, Local Life and Profile
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          Activity Feed, Journeys, Local Life and Profile is where you can go to
          view most of your information on Trotter It, including activities of
          yourself and users or travel agents you are following on the website.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            13. What your friends and others share about you
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          Users can tag other users as people with whom they have
          &lsquo;travelled with&rsquo;, while uploading their content. This
          information will be displayed in their Journeys, Local Life and
          Albums. If there is any disagreement, the same can be reported to the
          administrator of the website via &lsquo;Report a Problem&rsquo; in
          your settings.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            14. Unauthorized use
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          You shall not:
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.1. Use Trotter It&rsquo;s name, logo, trademark or branding without
          our express written permission
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.2. Access, tamper with or use non-public areas of Trotter It, our
          systems or our technical providers&rsquo; systems
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.3. Break or circumvent our security measures or otherwise test the
          vulnerability of our systems or networks; upload or distribute files
          that contain viruses, corrupted files, or any other similar software
          or programs that may damage the operation of the Website or
          another&rsquo;s computer; engage in any activity that interferes with
          or disrupts access to the Website or the Services (or the servers and
          networks which are connected to the Website); attempt to gain
          unauthorized access to any portion or feature of the Website, any
          other systems or networks connected to the Website, to any Trotter It
          server, or to any of the Services offered on or through the Website,
          by hacking, password mining or any other illegitimate means.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.4. Use any method to access, search, scrape, download or change
          Trotter It or anything on it (software and tools provided by Trotter
          It or popular browsers are okay)
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.5. Post large amounts of unwanted or repetitive stuff, post
          unsolicited commercial messages in comments, descriptions, etc., or
          try to artificially boost views, comments or other metrics vi. Use
          meta tags, hidden text or metadata with our trademark, logo, URL or
          product name without our written consent
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.6. Use Trotter It user information to forge any TCP / IP packet
          header or any part of the header information in any email or newsgroup
          posting, or in any way use Trotter It to send altered
          source-identifying information
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.7. Try to reverse engineer any of Trotter It&rsquo;s software or
          modify, copy, distribute, transmit, display, perform, reproduce,
          publish, license, create derivative works from, transfer, or sell any
          information or software obtained from the Website.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.8. Try to interfere with any Trotter It user, host or network, for
          example by sending a virus, overloading, spamming or mail-bombing
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.9. Collect or store personally identifiable information from
          Trotter It or its users without their permission
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.10. Impersonate or misrepresent your affiliation with any person or
          entity
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.11. Do anything that violates applicable law or regulations
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.12. Share your password, let anyone access your account or do
          anything that might put your account at risk
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.13. Sell your username or otherwise transfer it for compensation
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.14. Create or show ads that look like or could be confused with
          Trotter It content
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.15. Falsify or delete any author attributions, legal or other
          proper notices or proprietary designations or labels of the origin or
          source of software or other material contained in a file that is
          uploaded.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.16. Conduct or forward surveys, contests, pyramid schemes or chain
          letters
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          14.17. Encourage or help anyone do any of the things on this list
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            15. Cookies and Other Tracking Technologies
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          15.1. The Website utilizes cookies and other tracking technologies
          including any proprietary or third party tracking services. A cookie
          is a small text file that may be used, for example, to collect
          information about the Website activity. Some cookies and other
          technologies may serve to recall Personal and/or Non-Personal
          Information previously indicated by a Website user. Most browsers
          allow you to control cookies, including whether or not to accept them
          and how to remove them.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          15.2. You may set most browsers to notify you if you receive a cookie,
          or you may choose to block cookies with your browser, however, if you
          choose to erase or block your cookies, you shall have to re-enter your
          original user ID and password to gain access to certain parts of the
          Website.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          15.3. Tracking technologies may record information such as internet
          domain and host names, internet protocol (IP) addresses, browser
          software and operating system types, click stream patterns, and dates
          and times at which the Website is accessed. Our use of cookies and
          other tracking technologies allow us to improve our Website and your
          experience in using our Website.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            16. Limitation of Liability
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          16.1. Trotter It is not responsible for any breach of security or for
          any actions or inactions of any third parties that receive your
          Personal Information.
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          16.2. Notwithstanding anything contained in this Policy or elsewhere,
          Trotter It shall not be held responsible for any loss, damage or
          misuse of your Personal Information, if such loss, damage or misuse is
          attributable to a Force Majeure Event (as defined below).
        </p>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          16.3. A &ldquo;
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            <em>Force Majeure Event</em>
          </strong>
          &rdquo; shall mean any event that is beyond the reasonable control of
          Trotter It and shall include, without limitation, sabotage, fire,
          flood, explosion, acts of God, civil commotion, strikes, lockouts or
          industrial action of any kind, riots, insurrection, war, acts of
          government, computer hacking, civil disturbances, unauthorized
          &nbsp;access to computer data and storage device, computer crashes,
          breach of security and encryption, and any other similar events not
          within the control of Trotter It and which Trotter It is not able to
          overcome.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>17. Unsubscribe</strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          You have the option to unsubscribe from all Trotter It&rsquo;s
          newsletters and other general e-mail marketing communications by way
          of links provided at the bottom of each mailer. Trotter It respects
          your privacy and in the event that you choose to not receive such
          mailers, it shall take all adequate steps to remove you from such
          lists.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            18. Changes to this Policy
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          Trotter It reserves the right to update, change or modify this Policy
          at any time. The Policy shall come to effect from the date of such
          update, change or modification.
        </p>
        <h3
          className="display-6"
          style={{
            textAlign: "start",
            color: "rgb(48, 48, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "22px",
          }}
        >
          <strong style={{ color: "rgb(48, 48, 48)" }}>
            19. How to Contact Us
          </strong>
        </h3>
        <p
          style={{
            textAlign: "start",
            color: "rgb(137, 137, 137)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "14px",
          }}
        >
          If you have questions or concerns about this Policy, please contact us
          at info@TrotterIt.com
        </p>
      </div>
    </div>
  );
}

export default Policy;
