import React from "react";
import TavellHero from "../Components/TavellPage/TavellHero";

const Tavellpage = () => {
  return (
    <div>
      <TavellHero />
    </div>
  );
};

export default Tavellpage;
