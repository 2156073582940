import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function PaymanetSuccess() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/allvideos");
    }, 2000);
  }, []);
  return (
    <div className="vh-100 text-white d-flex flex-column align-items-center justify-content-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <div className="mt-4">
        <h2>
          Thank you for purchasing our VFX video. We will connect with you soon.
        </h2>
      </div>
    </div>
  );
}

export default PaymanetSuccess;
