import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-phone-number-input/style.css";

import Home from "./Pages/Home";
import Trending from "./Pages/Trending";
import ProfilePage from "./Pages/ProfilePage";
import StoryDetail from "./Pages/StoryDetail";
import AllVideo from "./Pages/AllVideo";
import FootprintPage from "./Pages/FootprintPage";
import CountrySection from "./Components/Country/HomeCountryection/CountrySection";
import Explore from "./Components/Explore/EditorChoiceHero";
import Tavellpage from "./Pages/Tavellpage";
import TavellDetailPage from "./Pages/TavellDetailPage";

import { useEffect } from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import TermsConditions from "./Pages/TermsConditions";
import Adveritsment from "./Pages/Adveritsment";
import TravelBlogs from "./Pages/TravelBlogs";
import TrotterItIcon from "./Components/TrotterItIcon";
import BlogDetails from "./Pages/BlogDetails";
import Policy from "./Pages/Policy";
import PolicyPage from "./Pages/PrivacyPolicy";
import { getToken } from "./Services/AuthApi";
import PaymanetSuccess from "./Pages/PaymanetSuccess";

function App() {
  async function getAuth() {
    if (!localStorage.getItem("auth-token")) {
      await getToken();
    }
  }
  useEffect(() => {
    getAuth();
    AOS.init({
      once: true,
    });
  }, []);
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />{" "}
          <Route path="/trending" element={<Trending />} />{" "}
          <Route
            path="/stories/:id/:username/:TripTitle"
            element={<StoryDetail />}
          />{" "}
          <Route path="/profile/:id" element={<ProfilePage />} />{" "}
          <Route path="/allvideos" element={<AllVideo />} />{" "}
          <Route path="/footprint" element={<FootprintPage />} />{" "}
          <Route path="/tavell" element={<Tavellpage />} />{" "}
          <Route path="/country/:countryName" element={<CountrySection />} />{" "}
          <Route path="/editor-choice" element={<Explore />} />{" "}
          <Route
            path="/tavell-detail/:id/:userName"
            element={<TavellDetailPage />}
          />
          <Route path="/terms-conditions" element={<TermsConditions />} />{" "}
          <Route path="/privacy-policy" element={<Policy />} />{" "}
          <Route path="/Returns-and-Refunds" element={<PolicyPage />} />{" "}
          <Route path="/Shipping-and-Delivery" element={<PolicyPage />} />{" "}
          <Route path="/advertise" element={<Adveritsment />} />{" "}
          <Route path="/travel-blog" element={<TravelBlogs />} />{" "}
          <Route path="/Blog/:id/:title" element={<BlogDetails />} />{" "}
          <Route
            path="/v2/phonepe/redirect-url/:PaymentID/:Price"
            element={<PaymanetSuccess />}
          />
        </Routes>{" "}
        <div className="position-fixed btm-20">
          <TrotterItIcon />
        </div>{" "}
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
