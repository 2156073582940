import React from "react";
import { getEditorChoice } from "../../Services/EditorChoice";
import { useState } from "react";
import { useEffect } from "react";
import { getImageURL, removeUnwanted, replaceSpaceWithHyphen } from "../../Services/Common/helper";
import { Link, Navigate, useNavigate } from "react-router-dom";
import eye from "../../Assets/png/Vector 8.png";
import track from "../../Assets/png/Vector 9.png";

const EditorChoiceHero = () => {
  const [editorChoice, setEditorChoice] = useState([]);
  const navigate = useNavigate();
  const getEditor = async () => {
    const res = await getEditorChoice();
    setEditorChoice(res);
  };
  useEffect(() => {
    getEditor();
  }, []);
  return (
    <div className="bg-white py-5">
      <div className=" custom_container">
        <h4 className="fs_48 fw-semibold color_gray1 text-center ff_opne">
          Editor Choice
        </h4>
        <p className="fs_20 color_gray1 text-center ff_opne">
          Explore a few travel stories that are our favourite
        </p>
        <div className="row pt-5">
          {editorChoice?.map((obj, i) => {
            return (
              <>
                <div
                  key={i}
                  className="col-md-4 col-sm-6 cursor-pointer mt-4 card-box position-relative"
                  onClick={() => {
                    navigate(`/stories/${obj._id}/${replaceSpaceWithHyphen(obj.user_name)}/${replaceSpaceWithHyphen(obj.title)}/`);
                  }}
                >
                  <div className="tripimg">
                    <img
                      src={
                        "https://storage.trotterit.com/images/trip/" + obj.image
                      }
                      className="cardimg"
                    />
                  </div>
                  <div className="position-absolute top-0 tripdetails p-3 w-100">
                    <div className="d-flex flex-column justify-content-between h-100 pe-3">
                      <div className="d-flex gap-2">
                        <Link to="#">
                          <div className="userPic">
                            <img
                              src={getImageURL(obj.profile_pic)}
                              className="cardimg"
                            />
                          </div>
                        </Link>
                        <div>
                          <span className="text-white">{obj.user_name}</span>
                        </div>
                      </div>
                      <div>
                        <h3 className="text-white">
                          {removeUnwanted(obj.title)}
                        </h3>
                      </div>
                      <div className="d-flex justify-content-between text-white">
                        <div className="d-flex flex-column align-items-center">
                          <span className="text-white">{obj.view_count}</span>
                          <div>
                            <img src={eye} alt="" srcset="" />
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <div>
                            <img src={track} />
                          </div>
                          <div>
                            <p>
                              {obj.halt_count} Footprints{" "}
                              {Math.round(obj.total_distance)} Km
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EditorChoiceHero;
