import React, { useEffect } from "react";
import Hero from "../Components/Hero";
import OverVision from "../Components/OverVision";
import Experinces from "../Components/Experinces";
import Carousel from "../Components/Carousel/CarouselConatiner";
import Country from "../Components/Country/Country";
import AddFeatured from "../Components/AddFeatured";
import Tourists from "../Components/Tourists";
import Adventure from "../Components/Adventure";
import Travelers from "../Components/Travelers";
import { Helmet } from "react-helmet";

function Home() {
  useEffect(() => {
    document.title = "Trotter It - Best Travel Journal and Social Media App"
  }, [])
  return (
    <div className="">
      <Helmet>
        <meta name="title" content={"Trotter It - Best Travel Journal and Social Media App"} />
        <meta
          name="description"
          content={"Trotter It is a Travel Journal App as well as a dedicated Social Networking App for Travellers around the world. Post, like, share your Travel Journeys in the most beautiful way with Trotter It mobile application."}
        />
        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://web.trotterit.com/home" />
        <meta property="og:title" content="Trotter It " />
        <meta
          property="og:description"
          content="Discover the World with Trottter It - Your Passport to Unforgettable Adventures!
          Explore our curated travel experiences, expert tips, and exclusive deals to make your journey extraordinary. Start
          planning your next adventure today!"
        />
        <meta
          property="og:image"
          content="./websiteImage.jpeg"
        />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://web.trotterit.com/home" />
        <meta property="twitter:title" content="Trotter It " />
        <meta
          property="twitter:description"
          content="Discover the World with Trottter It - Your Passport to Unforgettable Adventures!
          Explore our curated travel experiences, expert tips, and exclusive deals to make your journey extraordinary. Start
          planning your next adventure today!"
        />
        <meta
          property="twitter:image"
          content="./websiteImage.jpeg"
        />

      </Helmet>
      <Hero />
      <div className="mt-md-5 pt-md-3">
        <OverVision />
      </div>
      <Carousel />
      <Travelers />
      <Country />
      <Experinces />
      <AddFeatured />
      <Tourists />
      <Adventure />
    </div>
  );
}

export default Home;
