import React, { useEffect } from "react";
import Logo from "../../src/Assets/svg/logo.svg";
import Googlplay from "../Assets/svg/googlePlay.svg";
import AppStore from "../Assets/svg/appStore.svg";
import FooterPhone from "../Assets/png/footerPhone.png";
import Tilt from "vanilla-tilt";
const Adventure = () => {
  useEffect(() => {
    const footerPhoneElement = document.querySelector(".footer-phone");
    Tilt.init(footerPhoneElement, {
      max: 25,
      speed: 300,
      glare: true,
      "max-glare": 0.2,
    });
  }, []);
  return (
    <div className="bg-white adventure_bg">
      <div className="custom_container">
        <div className="row pt-5 pb-md-0 pb-5">
          <div className="col-md-6 pt-lg-5 mt-md-5">
            <img
              width={136}
              height={50}
              src={Logo}
              className="cursor-pointer"
            />
            <h4 className=" fs_48 text-white ff_opne mb-0 mt-3">
              Join the Adventure!
            </h4>
            <p className=" fs_20 text-white ff_opne mb-0 mt-3">
              Become part of an incredible community of travelers.
              <br className="d-md-flex d-none"></br> Document your travel
              journeys and connect with like-
              <br className="d-md-flex d-none"></br>
              minded explorers."
            </p>
            <div className="d-sm-flex gap-3 mt-lg-4 mt-xl-5 mt-3">
              <a href="https://play.google.com/store/apps/details?id=com.anansi.anansi&hl=en_IN&gl=US">
                <img className="scale" src={Googlplay} width={190} />
              </a>
              <a
                href="https://apps.apple.com/us/app/trotter-it-travel-journal-app/id6443733048"
                target="_blank"
              >
                <img className="scale" src={AppStore} width={190} />
              </a>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-md-end mt-4">
            <div className="transform">
              <img
                src={FooterPhone}
                className=" footer-phone rounded-5"
                width={320}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Adventure;
