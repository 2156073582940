import React, { useDebugValue, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getStoriesDetailsbyId,
  get_haltDetails_by_id,
} from "../../Services/Stories ";
import { useState } from "react";
import { getImageURL, removeUnwanted } from "../../Services/Common/helper";
import eye from "../../Assets/png/Vector 8.png";
import gray_ari from "../../Assets/svg/gray_aeroplane.svg";
import track from "../../Assets/png/Vector 9.png";
import TripStart from "../../Assets/img/Frame 53.png";
import StorySingleHaltDetails from "../Modals/StorySingleHalt/StorySingleHaltDetails";
import hart from "../../Assets/png/heart_icon.png";
import { useDispatch, useSelector } from "react-redux";
import StoryDetailsReducer, {
  fetchStoryDetails,
} from "../../Redux/StoryDetails/StoryDetailsReducer";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";

function StoryCard() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [seletedItem, setSelectedItem] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [userHalts, setUserHalts] = useState([]);
  const dispatch = useDispatch(StoryDetailsReducer);
  const storyData = useSelector((state) => state.StoryDetails.data);
  const haltData = useSelector((state) => state.StoryDetails.halt);
  const StoryDetails = async () => {
    setUserDetails(storyData);
  };

  const GethaltDetails = async () => {
    setUserHalts(haltData);
  };

  const handleImageClick = (index, item) => {
    setSelectedIndex(index);
    setSelectedItem(item);
    setShowModal(true);
  };
  useEffect(() => {
    dispatch(fetchStoryDetails(id));
  }, []);

  useEffect(() => {
    GethaltDetails();
  }, [haltData]);

  useEffect(() => {
    StoryDetails();
  }, [storyData]);

  useEffect(() => {
    if (userDetails) {
      document.title = userDetails?.title
    }
  }, [userDetails])
  return (
    <div>
      <Helmet>
        <title>{removeUnwanted(storyData?.title)}</title>
        <meta name="title" content={removeUnwanted(storyData?.title)} />
        <meta
          name="description"
          content={removeUnwanted(storyData?.description)}
        />
      </Helmet>
      <div className="left-side details">
        <div className="w-100 continerImage">
          <img
            src={
              "https://storage.trotterit.com/images/trip/" + userDetails?.image
            }
            width={"100%"}
            height={"100%"}
            className="rounded-lg"
            alt=""
            srcset=""
            style={{ objectFit: "cover" }}
          />
          <div className="d-flex flex-column justify-content-between position-absolute top-0 DesciptionContainer p-3">
            <div className="d-flex align-items-center gap-3">
              <div
                className="userPic2 cursor-pointer"
                onClick={() => navigate(`/profile/${userDetails?.user_id}`)}
              >
                <img
                  src={getImageURL(userDetails?.profile_pic)}
                  className="cardimg"
                />
              </div>
              <div className="text-white">
                <h5 className="ff_opne">{userDetails?.user_name}</h5>
                <p className="ff_opne">{userDetails?.user_place}</p>
              </div>
            </div>
            <div className="d-flex text-white justify-content-between align-items-center">
              <div className="text-white">
                <div>
                  <img src={eye} />
                </div>
                <div className="ff_opne">{userDetails?.view_count}</div>
              </div>
              <div>
                <div>
                  <img src={track} />
                </div>
                <div>
                  <p className=" ff_opne">
                    {userDetails?.halt_count} Footprints{" "}
                    {Math.round(userDetails?.total_distance)} Km
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3">
          <h1 className="display-6 ff_opne">
            {removeUnwanted(userDetails?.title)}
          </h1>
          <span className="trip-Desc ff_opne">
            {removeUnwanted(userDetails?.description)}
          </span>
        </div>
        <div className="d-flex gap-3 mt-4">
          {/* <div className="d-flex flex-column ">
            <div className="h-100 position-relative flex-column d-flex ">
              <img src={TripStart} />
              <span className="border_1 h-100 border-2 mx-auto mt-1"></span>
              <img
                className="icon_position1 position-absolute "
                src={gray_ari}
                alt=""
              />
            </div>
          </div> */}
          <div>
            <h4 className="ff_opne">Trip Start</h4>
            <div className="w-100 Trip-Container">
              {userHalts?.map((item, index) => {
                let image = item?.media?.map((media, index) => {
                  {
                    return media?.media?.split(",");
                  }
                });
                return (
                  <>
                    <div className="TripCardContianer Trip-Container shadow-sm my-4 w-100 p-3 rounded trip_border ">
                      <div className="headerContaier d-flex w-100 justify-content-between">
                        <div>
                          {" "}
                          <h3 className="ff_opne">
                            {removeUnwanted(item?.halt_title)}
                          </h3>
                        </div>
                        <div className="d-flex align-items-center gap-1">
                          <img src={hart} alt="" />
                          <p className="ff_opne fs_16 fw-medium mb-0">
                            {item?.like_count} likes
                          </p>
                        </div>
                      </div>
                      <div>
                        <span className="ff_opne">{item?.place}</span>
                        <p className="ff_opne">
                          {removeUnwanted(item?.why_halted)}
                        </p>
                      </div>
                      <div className="row">
                        {/* {item.media.media} */}
                        {item.media?.map((current, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="col-lg-4 col-6 mt-3"
                                onClick={() => {
                                  handleImageClick(index, item);
                                }}
                              >
                                {current.media.endsWith("mp4") ? (
                                  <ReactPlayer
                                    url={
                                      "https://storage.trotterit.com/images/halt/" +
                                      current.media
                                    }
                                    className="w-100 h-100 rounded vid"
                                    // controls
                                    muted
                                    autoplay={true}
                                  />
                                ) : (
                                  <img
                                    src={
                                      "https://storage.trotterit.com/images/halt/" +
                                      current.media
                                    }
                                    height={"150px"}
                                    className="rounded w-100"
                                    style={{ objectFit: "cover" }}
                                  />
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <StorySingleHaltDetails
        show={showModal}
        close={() => {
          setShowModal(false);
        }}
        userDetails={userDetails}
        ClickedImage={selectedIndex}
        selectHalt={seletedItem}
      />
    </div>
  );
}

export default StoryCard;
