import React, { useEffect, useState } from "react";
import asia_icon from "../../Assets/png/asia_icon.png";
import asia_icon1 from "../../Assets/png/asia_icon1.png";
import asia_icon2 from "../../Assets/png/asia_icon2.png";
import asia_icon3 from "../../Assets/png/asia_icon3.png";
import { getTrendingTrpis } from "../../Services/HomeServices";
import CountryTripsModal from "../Modals/CountryTripsModal";

const TrendingHero = () => {
  const [countryModal, setCountryModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");

  return (
    <>
      <div className="bg-white">
        <div className="container  custom_container py-5 ">
          <h2 className="text-center  mb-0 fs_48 fw-normal ff_opne color_gray1">
            Trending Adventures
          </h2>
          <p className="text-center mb-0 pt-2 fs_24 fw-normal ff_opne color_gray3 mt-2">
            Discover the Hottest Travel Destinations and Stories
          </p>
        </div>
        <CountryTripsModal
          show={countryModal}
          close={() => setCountryModal(false)}
          country={selectedCountry}
        />
      </div>
    </>
  );
};

export default TrendingHero;
