import axios from "axios";
import { getToken } from "../AuthApi";
import { axiosInstance } from "../Common/AxiosInstanc";
import { decryptResponse } from "../Common/helper";

export const WithinGeo = async (Location) => {
  try {
    const data = {
      lat: Location.lat,
      lon: Location.loc,
    };
    const url = "within_geo/halts?index=0";
    const res = await axiosInstance({
      url: `${url}`,
      method: "POST",
      data: data,
    });
    if (res.status === 200) {
      if (res?.data?.data) {
        const key = localStorage.getItem("aax");
        const re = decryptResponse(key, res?.data?.data);
        return re;
      } else {
        return [];
      }
    }
  } catch (error) {}
};
